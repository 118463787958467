import { motion } from "framer-motion"
import React from "react"

import { IntegrationFlags, ProfilePanelPermissions, QueryPositionArgs } from "types/graphql"
import { opaqueDelay } from "v2/react/utils/framerAnimationVariants"

import { PositionHeader } from "./PositionHeader"
import { PositionModules } from "./PositionModules/PositionModules"

interface PositionTabContentProps {
  abilities: ProfilePanelPermissions
  integrationFlags: IntegrationFlags
  pjaxReloadContainer?: string
  pjaxReloadUrl?: string
  positionQuery: (params: QueryPositionArgs) => void
}
const PositionTabContent = ({
  abilities,
  integrationFlags,
  pjaxReloadContainer,
  pjaxReloadUrl,
  positionQuery,
}: PositionTabContentProps) => (
  <motion.div key="position" initial="hidden" animate="shown" exit="hidden" variants={opaqueDelay}>
    <PositionHeader
      abilities={abilities}
      positionQuery={positionQuery}
      pjaxReloadContainer={pjaxReloadContainer}
      pjaxReloadUrl={pjaxReloadUrl}
    />
    <PositionModules abilities={abilities} integrationFlags={integrationFlags} />
  </motion.div>
)

export default PositionTabContent
