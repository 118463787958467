import { FieldType } from "v2/react/components/orgChart/Datasheet/types"
import {
  isForcedAutocompleteField,
  isSuggestedAutocompleteField,
} from "v2/redux/slices/GridSlice/gridHelpers/collections"
import type { FieldKey } from "v2/redux/slices/NodeSlice/types"

export const getFieldType = ({
  fieldKey,
  hasCollection,
  isEditable,
}: {
  fieldKey: FieldKey
  hasCollection: boolean
  isEditable: boolean
}): FieldType => {
  if (!isEditable) return FieldType.NonEditable
  if (fieldKey === "name") return FieldType.PersonAutocomplete
  if (fieldKey === "reports_to") return FieldType.PositionAutocomplete
  if (fieldKey.startsWith("org_unit_type_")) return FieldType.OrgUnitAutocomplete
  if (hasCollection) return FieldType.SelectDropdown
  if (isSuggestedAutocompleteField(fieldKey)) return FieldType.SuggestedAutocomplete
  if (isForcedAutocompleteField(fieldKey)) return FieldType.ForcedAutocomplete
  return FieldType.Standard
}
