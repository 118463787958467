/* eslint-disable
  no-use-before-define,
  no-underscore-dangle,
  no-param-reassign,
  no-plusplus,
  max-len,
  no-return-assign,
  no-mixed-operators,
  consistent-return,
  prefer-template,
  func-names
*/
import $ from "jquery"

import { ColorCoding } from "v2/color_coding"
import { IconCommandCenter } from "v2/iconCommandCenter"

import behavior from "./behavior"
import exportStyles from "./constants/exportStyles"
import defaultOptions, { globals } from "./constants/options"
import effects from "./effects"
import applyLayoutAdjustments, { d3TreeSeparation } from "./layoutAdjustments"
import {
  peopleDots as addPeopleDots,
  avatars,
  chainOfCommand,
  colorCodeChartNodes,
  hoverStateButtons,
  statsBars,
  cardEdgeColors as updateCardEdgeColorElements,
} from "./node"
import defineChartDefs from "./svg/defs"
import generateChartSectionGroupingPoints from "./utils/chartSectionGroupingPoints"
import { connectChartToRedux } from "./utils/connectChartToRedux"
import DragHelper from "./utils/dragHelper"
import nodeLinkGenerators from "./utils/nodeLinkGenerators"
import NodePositioningHelper from "./utils/nodePositioningHelper"
import PositionDataLoader from "./utils/positionDataLoader"
import RelationalNodeDataStore from "./utils/relationalNodeDataStore"
import statsHelpers from "./utils/statsHelpers"
import { toTitleCase, wrap } from "./utils/textUtils"
import TooltipHandler from "./utils/tooltipHandler"
import transitionsEnded from "./utils/transitionsEnded"
import truncate from "./utils/truncator"

const iconCommandCenter = IconCommandCenter.establishCommand()
const error = (message) => {
  throw new Error(`Org Chart - ${message}`)
}

/**
 * @see ColorCoding.ColorCoder Exports the backing interface as pure functions;
 *   useful for customizing the args passed in.
 * @return {object} An object holding three functions for color coding, bound to
 *   the current state: `codeCardColor`, `codeEdgeColor`, and `codeTextColor`.
 *   Each one accepts a node as its first argument and an optional second
 *   argument for a fallback value if color coding fails.
 */
const colorCoder = () => ColorCoding.getColorCoderWithState()

class OrgChart {
  constructor(selector, overrideOptions) {
    const { d3 } = window
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const chart = this
    let options = { ...defaultOptions, ...overrideOptions }
    const collapsedNodeIdLookup = {}
    const isHierarchialView = options.displayMode !== "cards"
    const makeOrgChartNode = (data) => new window.OrgChartNode(data, { chart })
    const getFields = () => options.displayFields
    let positioningHelper = new NodePositioningHelper(getFields(), options)
    const staticCompanyNodeFields = ["name"]
    const companyNodePositioningHelper = new NodePositioningHelper(
      staticCompanyNodeFields.concat(getFields().indexOf("avatar") > -1 ? ["avatar"] : []),
      options,
      {},
      true,
    )
    const related = RelationalNodeDataStore.instance

    // Let callers declare collapsed nodes ahead of render time.
    _.each(options.collapsedNodeIds || [], (id) => {
      collapsedNodeIdLookup[id] = true
    })

    const allNodes = () => {
      let nodes = [root]
      const appendVisibleAndInvisibleChildren = (node) => {
        if (node._children) {
          nodes = nodes.concat(node._children)
          node._children.forEach(appendVisibleAndInvisibleChildren)
        }
        if (node.children) {
          nodes = nodes.concat(node.children)
          node.children.forEach(appendVisibleAndInvisibleChildren)
        }
        if (node.assistants) {
          nodes = nodes.concat(node.assistants)
        }
      }
      appendVisibleAndInvisibleChildren(root)
      return nodes
    }

    const focusNode = (node) => {
      const farthest = getFarthestCollapsedAncestor(node)
      expandAncestry(node)
      return d3
        .transition()
        .duration(duration)
        .each(() => {
          update(farthest, false)
          return context.selectAll("g.node").each(function (d) {
            if (d._id === node._id) {
              return focusElement(this)
            }
          })
        })
    }

    const getFarthestCollapsedAncestor = (d) => {
      let farthest = d
      let parent = farthest
      while (parent.parent) {
        parent = parent.parent
        if (!parent.children) {
          farthest = parent
        }
      }
      return farthest
    }

    const expandAncestry = (d) => {
      if (d.parent) {
        if (!d.parent.children) {
          chart.trigger("node-expanded", d.parent.id)
          d.parent.children = d.parent._children
          d.parent._children = null
        }
        return expandAncestry(d.parent)
      }
    }

    /**
     *  @param {Object} node - The data object associated with the node to be
     *    updated. Note that this is not a d3.Selection object, but rather the data
     *    that is bound to a specific D3 element.
     *  @param {boolean} shouldFocus - A flag to indicate if the node should be focused after the update.
     */
    const updateNode = function (node, shouldFocus = false) {
      const element = context.selectAll(`g.node#node-${node._id}`)
      const isCompanyNode = node === "root" && node.klass === "Company"

      if (!element.empty() && (node === root || !element.datum()?.node_base_fully_rendered)) {
        fullyRenderNodeBase(element)
      }

      element.attr("class", (d) => {
        if (options.displayMode === "cards") {
          return "node"
        }

        if (isDisplayedAsAssistant(d)) {
          return "node assistant"
        }
        return d.depth === 0 ? "node root" : "node"
      })
      if (isHierarchialView) {
        maybeRenderChildCountElements(element)

        if (options.displayMode === "three_level" && node === root) {
          chainOfCommand.update(
            element,
            positioningHelper,
            getExtraChartSectionLabelHeight(),
            duration,
          )
        }
      }

      updateCardEdgeColorElements(element, positioningHelper)

      element
        .select("rect.card")
        .attr("data-hydrated", true)
        .each(renderCard)
        .each(renderCardDimensions)
        .each(displayTopOnClick)

      element.selectAll("rect.card-inner-shadow").each(renderCardDimensions).each(displayTopOnClick)
      element.select("image.avatar").each(avatars.setImageSource)

      element.selectAll(".mouse-target").remove()
      element.selectAll(`text.name, text.title, text.${emptyPositionTextClass}`).remove()
      if (shouldDisplayField("name") || isCompanyNode) {
        element
          .append("text")
          .each(renderNameText)
          .each(setNamePosition)
          .each(displayTopOnClick)
          .each(wrapOrTruncateName)
      }

      element.selectAll(`text.${emptyPositionTextClass}`).each(bindClickNameHandler)
      element.selectAll("text.name").each(displayTopOnClick)
      if (!isCompanyNode) {
        if (shouldDisplayField("title")) {
          element
            .append("text")
            .each(renderTitleText)
            .each(setTitlePosition)
            .each(displayTopOnClick)
            .each(wrapOrTruncateTitle)
        }

        const posHelper = positioningHelper
        if (options.displayMode === "cards") determineShowRole(element)
        // eslint-disable-next-line no-restricted-syntax
        for (const field of getFields()) {
          element
            .selectAll(`text[data-field=${field}]`)
            .text((d) => {
              const label = toTitleCase(field.replace("_", " "))
              return `${label}: ${d[field]}`
            })
            .attr("y", () => posHelper.getFieldYPosition(field))
        }
        element.selectAll("text.display-field, text.display-field-label, svg.lock").remove()
        addExtraFields(element)
        element.selectAll("text.role, path.role-background").remove()
        renderRole(element)
        if (node.assistants) {
          renderAssistants(node)
        }
        element.each(removeChartSectionLabel)
        if (actsAsChartSectionHead(node) && isHierarchialView) {
          element.each(renderChartSectionLabel)

          const nodes = tree.nodes(root)
          nodes.forEach(assignNodeOffsetsForThreeLevel)
          nodes.forEach(adjustForLayoutOptions)
          renderChartSectionGroupings(nodes)
        }

        if (node === root && $("g.assistant").length > 0 && !assistantsCanBeDisplayed()) {
          node.children.forEach((d) => {
            if (statsHelpers.hasVisibleAssistants(d)) {
              if (d.children.length === 0) {
                d.children = []
              }
              d.children = d.children.concat(d.assistants)
              d.assistants = []
              update(d)
            }
          })
        }

        element.selectAll("g.people-dots").remove()

        element.each(function (d) {
          addPeopleDots(
            d3.select(this),
            (id, personId) => chart.trigger("click.switchPeople", id, personId),
            positioningHelper.getPeopleDotsYOffset(d),
          )
        })
      }

      if (shouldFocus) {
        focusNode(node)
      }

      colorCodeChartNodes(element, colorCoder())
    }

    const useExtraFieldState = () => {
      const fields = getExtraFields()
      let accExtraFieldIndex = 0
      let accExtraLines = 0
      const extraLinesLookup = {}
      const extraFieldIndexLookup = {}
      _.each(fields, (field) => {
        extraFieldIndexLookup[field] = accExtraFieldIndex
        extraLinesLookup[field] = accExtraLines

        if (extraLinesMap[field]) accExtraLines += extraLinesMap[field]
        accExtraFieldIndex += 1
      })

      return { extraLinesLookup, extraFieldIndexLookup, fields }
    }

    // Render each extra field (any field that is not a default from
    // defaultFields)
    const addExtraFields = (elements) => {
      const { getShortFieldLabel, hasFieldDefinition, valueOf } = related
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const { extraLinesLookup, extraFieldIndexLookup, fields } = useExtraFieldState()
      const getFieldValue = (node, field) => {
        if (node.klass === "Company") return ""
        if (!hasFieldDefinition(field))
          return Object.prototype.hasOwnProperty.call(node, field) ? node[field] : "locked"

        const raw = valueOf(field, node)
        return raw !== undefined ? raw : "locked"
      }

      elements.each(function (node) {
        // Render labels (if shown).
        if (options.showLabels) {
          const labels = d3.select(this).selectAll("text.display-field-label").data(fields)
          labels.enter().append("text").attr("class", "display-field-label")
          labels.each(function (field) {
            if (node.klass === "Company") return

            const fieldLabel = hasFieldDefinition(field)
              ? getShortFieldLabel(field)
              : ("short_field_" + field).t("org_chart")
            const textFill = colorCoder().codeTextColor(node)
            const text = renderTextField(this, fieldLabel, "display-field-label", textFill)

            const extraFieldIndex = extraFieldIndexLookup[field]
            const extraLines = extraLinesLookup[field]
            const renderPositioning = setExtraFieldPosition(
              extraFieldIndex,
              extraLines,
              "start",
              field,
            )
            text.each(renderPositioning).each(displayTopOnClick)

            if (hasFieldDefinition(field)) text.call(truncateWithTooltip, "text", "left")
          })
        }

        // Next, render out svg locks for field values that will be obfuscated.
        const lockedFields = fields.filter((field) => {
          const fieldValue = getFieldValue(node, field)

          const isLoadedAndLocked = node.loaded && fieldValue === "locked"
          const isLimitedAdmin = gon.is_limited_admin
          const inSubordinateIds = options.subordinateIds?.includes(node.id)

          return isLoadedAndLocked && isLimitedAdmin && inSubordinateIds
        })

        const svgLocks = d3.select(this).selectAll("svg.locks").data(lockedFields)
        svgLocks
          .enter()
          .append("svg")
          .attr("class", "lock")
          .attr("width", 10)
          .attr("height", 12)
          .append("use")
          .attr("xlink:href", "#lock")
        svgLocks
          .each(function (field) {
            const extraFieldIndex = extraFieldIndexLookup[field]
            const extraLines = extraLinesLookup[field]
            setExtraFieldPosition(extraFieldIndex, extraLines, "end", field).call(this)
          })
          .each(displayTopOnClick)
          .each(function () {
            tooltipHandler.add(
              d3.select(this),
              "You don't have permission to view this data.",
              "left",
            )
          })

        const displayFieldValues = d3.select(this).selectAll("text.display-field").data(fields)
        displayFieldValues.enter().append("text").attr("class", "display-field-value")
        displayFieldValues.each(function (field) {
          const extraFieldIndex = extraFieldIndexLookup[field]
          const extraLines = extraLinesLookup[field]

          let fieldValue = getFieldValue(node, field)
          if (fieldValue === "locked") fieldValue = ""

          const textWidth = options.showLabels ? maxWidth / 2 : maxWidth
          const textMeasurement = parseInt(positioningHelper.measureTextInElement(this), 10)

          const text = renderTextField(
            this,
            fieldValue,
            "display-field",
            colorCoder().codeTextColor(node),
          )

          if (shouldWrap(field) && textMeasurement > textWidth) {
            const alignment = options.showLabels ? "end" : "middle"

            return text
              .call(wrap, textWidth)
              .each(setExtraFieldPosition(extraFieldIndex, extraLines, alignment, field))
              .each(displayTopOnClick)
          }

          const tooltipAlignment = options.showLabels ? "right" : undefined

          return text
            .each(setExtraFieldPosition(extraFieldIndex, extraLines, "end", field))
            .each(displayTopOnClick)
            .call(truncateWithTooltip, "text", tooltipAlignment)
        })
      })
    }

    const renderRole = (elements) => {
      if (isHierarchialView) {
        return
      }

      return elements.each(function (d) {
        if (!d.role) {
          return
        }
        const element = d3.select(this)
        const cardBottom = positioningHelper.cardYPosition() + positioningHelper.getNodeHeight()
        const roleHeight = 21

        element
          .insert("path", ".color")
          .attr(
            "d",
            `
               M${-nodeWidth / 2},${cardBottom - 21}
               h${nodeWidth}
               v${roleHeight - globals.extraLineHeight}
               a${globals.nodeRadius},${globals.nodeRadius} 0 0 1 ${-globals.nodeRadius},${
                 globals.nodeRadius
               }
               h${-(nodeWidth - 2 * globals.nodeRadius)}
               a${globals.nodeRadius},${
                 globals.nodeRadius
               } 0 0 1 ${-globals.nodeRadius},${-globals.nodeRadius}
             `,
          )
          .attr("fill", colorCoder().codeCardColor(d, "rgba(27, 98, 255, 1)"))
          .attr("class", "role-background")

        if (ColorCoding.state.isCardColorEnabled) {
          element
            .selectAll(".role-background")
            .attr("fill", "none")
            .style("stroke-width", "0.25")
            .style("stroke-dasharray", "142, 182")
            .style("stroke", colorCoder().codeTextColor(d, "#ffffff"))
        }

        element
          .append("text")
          .text(d.role)
          .attr("class", "role")
          .attr("fill", colorCoder().codeTextColor(d, "#ffffff"))
          .attr("x", -positioningHelper.measureText(d.role, "name").width / 2)
          .attr("y", cardBottom - 7)
          .style({
            "font-family": "Satoshi",
            "font-size": "10px",
          })
      })
    }

    const updateLink = (node) => {
      if (!node) {
        return
      }
      const element = polylineGroup
        .selectAll("path")
        .filter((filtering) => filtering && filtering.target && filtering.target._id === node._id)

      return element.each(renderStrokeDashArray)
    }

    const totalVerticalSpacing = () =>
      options.verticalSpacing + (isHierarchialView ? getExtraChartSectionLabelHeight() : 0)

    const nodeHeightWithVerticalSpacing = () =>
      totalVerticalSpacing() + positioningHelper.getNodeHeight()

    const getAssistantVerticalSpacing = () =>
      nodeHeightWithVerticalSpacing() - options.verticalSpacing

    const getExtraFields = () =>
      _.reject(options.displayFields, (field) => _.includes(globals.defaultFields, field))

    const getAggregate = (key) =>
      root?.meta?.aggregates[`${key}s`] || (dataArr ? dataArr[0]?.meta?.aggregates[`${key}s`] : [])

    const getExtraChartSectionLabelHeight = _.memoize(
      () => {
        if (!shouldWrap("chart_section")) {
          return 0
        }
        const chartSectionsAggregate = getAggregate("chart_section")
        if (!chartSectionsAggregate || !chartSectionsAggregate.length) {
          return 0
        }
        const chartSections = getAggregate("chart_section")
        const linesNeeded = _.map(chartSections, (chartSection) =>
          calculateLineCount(chartSection, "chart_section_name"),
        )
        const max = _.max(linesNeeded)
        return parseInt((max - 1) * 14, 10)
      },
      () => root.id + getAggregate("chart_section").join("") + options.enableChartSections,
    )

    const isDescendantOfNode = (descendant, node) => {
      if (descendant.parent === node) {
        return true
      }
      if (!descendant.parent) {
        return false
      }
      return isDescendantOfNode(descendant.parent, node)
    }

    const assignNodeOffsetsForThreeLevel = function (d) {
      if (options.displayMode !== "three_level") {
        return
      }
      if (!((d.depth === 1 || d.depth === 2) && d.parent.children.length >= 1)) {
        return
      }
      const childIndex = d.parent.children
        .slice()
        .reverse()
        .findIndex((context) => context.id === d.id)
      const parentX = d.parent.x
      const numberOfChildren = d.parent.children.length
      const padding = globals.threeLevelNodePadding + globals.threeLevelShiftAmount
      const totalNodeWidth = nodeWidth + padding
      return (d.x =
        parentX -
        childIndex * totalNodeWidth +
        (numberOfChildren * totalNodeWidth) / 2 -
        totalNodeWidth / 2)
    }

    const assistantsAreDisplayed = () => {
      if (!assistantsCanBeDisplayed()) {
        return false
      }
      if (statsHelpers.hasVisibleAssistants(root)) {
        return true
      }

      return (
        root.children.length === 1 &&
        root.children.filter(statsHelpers.hasVisibleAssistants).length > 0
      )
    }

    const assistantsShownAbove = (d) => {
      if (d.depth === 0) {
        return false
      }

      if (d.depth === 1) {
        return statsHelpers.hasVisibleAssistants(d.parent)
      }

      return assistantsAreDisplayed()
    }

    const adjustForLayoutOptions = (d) => {
      applyLayoutAdjustments(
        d,
        options,
        assistantsShownAbove,
        nodeHeightWithVerticalSpacing(),
        getExtraChartSectionLabelHeight(),
        positioningHelper,
      )
    }

    const shouldDisplayField = (field) => _.includes(options.displayFields, field)

    // Truncate the text content of a text element and add a tooltip based on the
    // element's original text content.
    const truncateWithTooltip = function (textElement, style = "text", position = "center") {
      truncate.call(this, textElement, style, position, options.showLabels, function (value, dim) {
        tooltipHandler.add(this, value, position, dim)
      })
    }

    const startingCoordinates = (d) => {
      const x = typeof d.x === "number" ? d.x : 0
      const y = typeof d.y === "number" ? d.y : 0

      return { x, y }
    }

    const renderAssistants = (d) => {
      const links = []
      const assistantsList = d.assistants
      // If we need to remove assistants, it's important that an empty array
      // is stored in `assistants`, otherwise the assistants will not exit the chart.
      if (!assistantsList) return

      const startingPosition = startingCoordinates(d)
      assistantsList.forEach((assistant, index) => {
        // Start on right, next left, then two positions to right, two to left
        const multiplier = index % 2 === 0 ? 1 : -1
        assistant.x =
          startingPosition.x +
          (nodeWidth + globals.nodePadding) * Math.round((index + 1) / 2) * multiplier
        assistant.y = startingPosition.y + getAssistantVerticalSpacing()
        assistant.depth = d.depth + 1
        assistant.is_assistant = true
        // Need to manually assign parent here; d3 .tree handles this normally
        const assistantParent = chart.find(assistant.parent_id)
        if (!assistantParent) {
          return
        }
        assistant.parent = assistantParent.attributes
        links.push({
          source: d,
          target: assistant,
        })
      })

      const assistants = svgGroup
        .selectAll("g.node.assistant")
        .data(assistantsList, (d) => d._id || (d._id = ++internalIdCounter))
      assistants
        .exit()
        .transition()
        .duration(duration)
        .attr("transform", () => `translate(${startingPosition.x},${startingPosition.y})`)
        .remove()

      enterNodeBaseGroup(assistants.enter(), startingPosition, "node assistant").call(
        fullyRenderNodeBase,
      )

      const linkList = polylineGroup
        .selectAll("polyline.assistant-link")
        .data(links, (d) => `${d.target.id}_${d.source.id}`)

      linkList.enter().call(drawAssistantsLink)
      linkList
        .transition()
        .duration(duration)
        .attr("points", (d) =>
          nodeLinkGenerators.linkPointsForAssistant(
            d,
            positioningHelper.getCardVerticalCenter(),
            positioningHelper.getNodeHeight(),
          ),
        )
        .each("end", function () {
          return d3.select(this).transition().duration(250).attr("opacity", 1)
        })
      linkList.exit().remove()

      d3.transition()
        .duration(duration)
        .each(() => {
          assistants
            .transition()
            .duration(duration)
            .attr("transform", (a) => "translate(" + a.x + "," + a.y + ")")
            .select("text")

          assistantsList.forEach((d) => {
            d.x0 = d.x
            d.y0 = d.y
          })
        })
    }

    const isTopLevel = (d) => {
      if (d.is_chart_section_head && d.parent && d.parent.klass === "ChartSection") {
        return true
      }

      return d === root || d.parent_id === null
    }

    const assistantsCanBeDisplayed = () =>
      root.klass === "Position" || (root.children && root.children.length === 1)

    const assistantsCanBeDisplayedForNode = (node) =>
      assistantsCanBeDisplayed() && node.klass === "Position" && isTopLevel(node)

    const isDisplayedAsAssistant = (d) =>
      assistantsCanBeDisplayed() &&
      d.is_assistant &&
      d.parent &&
      isTopLevel(d.parent) &&
      d.parent.assistants &&
      d.parent.assistants.length > 0

    // Enter in the base <g class="node"> elements
    const enterNodeBaseGroup = function (nodeList, startingPosition, baseClass = "node") {
      return nodeList
        .insert("g")
        .attr("id", (d) => `node-${d._id}`)
        .attr("visibility", setNodeVisibility)
        .attr("class", (d) => (d.depth === 0 ? `${baseClass} root` : baseClass))
        .attr("transform", () => {
          const { x, y } = startingPosition
          return `translate(${x},${y})`
        })
        .attr("style", (d) =>
          typeof d.ancestors === "function" &&
          d.ancestors().find((a) => a !== d && a.data?.inProgress)
            ? "opacity: 0.5"
            : "",
        )
    }

    const shouldRenderDividerLine = () =>
      (shouldDisplayField("avatar") || shouldDisplayField("title") || shouldDisplayField("name")) &&
      getExtraFields().length > 0

    // Append the base <g> with all other node base elements
    // Should this be restricted to `enter` scope?
    const fullyRenderNodeBase = function (node) {
      const nonCompanyNodes = node.filter((d) => d.klass !== "Company")
      const companyNodes = node.filter((d) => d.klass === "Company")

      node.on("mouseenter", attachDragListenerJustInTime).on("mouseleave", handleGroupingMouseLeave)

      // NOTE: This card shadow is a non-essential element. If it affects
      // performance negatively, we can either remove it or conditionally remove
      // it with some sort of performance-focued mode
      const shadows = node
        .selectAll("rect.card-inner-shadow")
        .data((d) => [d])
        .enter()
        .append("rect")
      shadows
        .each(function () {
          // TODO: in later versions of d3, this can be replaced with
          // selection.lower()
          this.parentNode.insertBefore(this, this.parentNode.firstChild)
        })
        .each(renderCardShadow)
        .each(renderCardPosition)
        .each(renderCardDimensions)
        .on("mouseenter", handleNodeMouseEnter)
        .on("mouseleave", handleNodeMouseLeave)

      node
        .selectAll("rect.card")
        .data((d) => [d])
        .enter()
        .append("rect")
        .each(function () {
          // TODO: in later versions of d3, this can be replaced with
          // selection.lower()
          this.parentNode.insertBefore(this, this.parentNode.firstChild)
        })
        .each(renderCard)
        .each(renderCardPosition)
        .each(renderCardDimensions)
        .on("mouseenter", handleNodeMouseEnter)
        .on("mouseleave", handleNodeMouseLeave)

      // Add a small, non-visible, marker that represents an area of the node that
      // we can always drag with.
      node
        .selectAll("rect.node-handle")
        .data((d) => [d])
        .enter()
        .append("rect")
        .attr("class", "node-handle")
        .attr("x", nodeWidth / 2 - 10)
        .attr("y", positioningHelper.getNodeHeight() / 2)
        .attr("width", 10)
        .attr("height", 10)
        .attr("opacity", 0)
        .attr("visibility", 0)
        .on("mouseenter", handleNodeMouseEnter)
        .on("mouseleave", handleNodeMouseLeave)

      const companyNameTexts = companyNodes.selectAll("text.name").data((d) => [d])

      companyNameTexts
        .enter()
        .append("text")
        .attr("class", "name")
        .each(renderNameText)
        .each(setNamePosition)
        .each(wrapOrTruncateName)

      companyNameTexts.exit().remove()

      if (shouldDisplayField("name")) {
        nonCompanyNodes
          .selectAll("text.name")
          .data((d) => [d])
          .enter()
          .append("text")
          .attr("class", "name")
          .each(renderNameText)
          .each(setNamePosition)
          .each(wrapOrTruncateName)
      }
      maybeRenderChildCountElements(node)

      if (shouldDisplayField("avatar") && node.select("image.avatar").empty()) {
        node
          .selectAll("image.avatar")
          .data((d) => [d])
          .enter()
          .call(() => avatars.append(node, displayTopOnClick))
      }
      if (shouldDisplayField("title") && node.select("text.title").empty()) {
        nonCompanyNodes
          .selectAll("text.title")
          .data((d) => [d])
          .enter()
          .append("text")
          .each(renderTitleText)
          .each(setTitlePosition)
          .each(wrapOrTruncateTitle)
      }

      if (shouldRenderDividerLine()) {
        const dividerTopLines = nonCompanyNodes.selectAll("line.divider-top").data((d) => [d])
        const linePositionInfo = positioningHelper.fieldPositionInfo("divider-top")
        dividerTopLines.exit().remove()
        dividerTopLines.enter().append("line").attr("class", "divider-top")

        const topOffset = linePositionInfo.y

        dividerTopLines
          .attr("x1", -(globals.nodeWidth / 2) + globals.nodePadding)
          .attr("x2", -(globals.nodeWidth / 2) + globals.nodeWidth - globals.nodePadding)
          .attr("y1", topOffset)
          .attr("y2", topOffset)
          .attr("stroke", globals.statsBars.borderColor)
          .attr("fill", "transparent")
          .attr("stroke-width", "1px")
      }

      addExtraFields(nonCompanyNodes)
      nonCompanyNodes.filter(actsAsChartSectionHead).each(renderChartSectionLabel)
      nonCompanyNodes.filter(hasSubPageNum).each(renderSubPageNum)
      nonCompanyNodes.filter(hasParentPageNum).each(renderParentPageNum)
      updateCardEdgeColorElements(nonCompanyNodes, positioningHelper)
      colorCodeChartNodes(node, colorCoder())

      node.datum((d) => {
        d.node_base_fully_rendered = true
        return d
      })
    }

    const maybeRenderChildCountElements = (nodeSelection) => {
      if (!isHierarchialView) return
      if (options.displayMode === "cards") return

      const clickHandler = options.displayMode === "three_level" ? null : handleReportsRectClick

      const nonCompanyNodes = nodeSelection.filter(
        (d) => d.klass !== "Company" && !d.data?.inProgress,
      )
      const companyNode = nodeSelection.filter((d) => d.klass === "Company")
      const inProgressNodes = nodeSelection.filter(
        (d) => d.klass !== "Company" && d.data?.inProgress,
      )

      statsBars.update(nonCompanyNodes, positioningHelper, clickHandler)
      statsBars.updateInProgress(inProgressNodes, positioningHelper, clickHandler)
      statsBars.update(companyNode, companyNodePositioningHelper, clickHandler)
    }

    chart.updateFromRoot = function (center = false) {
      update(root, center)
    }

    const update = function (data, center = true) {
      iconCommandCenter.unmount()
      let nodes
      if (isHierarchialView) {
        nodes = tree.nodes(root)
        nodes.forEach(assignNodeOffsetsForThreeLevel)
        nodes.forEach(adjustForLayoutOptions)

        if (options.displayMode === "three_level") {
          nodes = nodes.filter((d) => d.depth <= 2)
        }
      } else {
        nodes = tree.nodes(data, viewerWidth)
      }

      // A similar function is called in +renderAssistants+ to take care of this for assistant nodes.
      const nodeList = svgGroup
        .selectAll("g.node:not(.assistant)")
        .data(nodes, (d) => d._id || (d._id = ++internalIdCounter))

      updateNode(data)

      // Insert base <g> elements for all nodes in the enter() selection.
      const enterSelection = enterNodeBaseGroup(nodeList.enter(), startingCoordinates(data))

      // If we're loading async, we don't need to render the rest of the node.
      // Nodes are fully rendered as part of +updateNode+
      if (!(options.loadAsync && !initialized)) {
        // Finish rendering each node
        enterSelection.call(fullyRenderNodeBase)
      }

      if (isHierarchialView) {
        nodeList.each(function () {
          maybeRenderChildCountElements(d3.select(this))
        })

        // Remove any unneeded nodes that are no longer descendants of anything.
        nodeList
          .exit()
          .filter((d) => !isDescendantOfNode(d, data))
          .remove()
      }

      if (options.displayMode === "three_level") {
        nodeList
          .filter((d) => d === root)
          .each(function (d) {
            chainOfCommand.addTo.call(
              this,
              d,
              positioningHelper,
              chart,
              getExtraChartSectionLabelHeight(),
            )
          })
      }

      // Set node coordinates. This is important for rendering.
      d3.transition()
        .duration(duration)
        .each(() => {
          nodeList
            .transition()
            .duration(duration)
            .attr("transform", (d) => {
              if (d.klass === "Company") {
                return `translate(${d.x},${d.y + companyNodeFieldYOffset()})`
              }
              return "translate(" + d.x + "," + d.y + ")"
            })
            .select("text")
            .style("fill-opacity", 1)
          // remove all other unneeded nodes, transitioning to the root position
          // first.
          nodeList
            .exit()
            .filter((d) => isDescendantOfNode(d, data))
            .transition()
            .duration(duration)
            .attr("transform", () => "translate(" + data.x + "," + data.y + ")")
            .remove()
          nodes.forEach((d) => {
            d.x0 = d.x
            return (d.y0 = d.y)
          })
        })

      if (isHierarchialView) {
        updateLinks(nodes)
        if (center && !(options.displayMode === "three_level" && data.depth > 2)) {
          centerNode(data)
        }
      }

      if (options.loadAsync) {
        fetchInitialPositionData()
      }

      nodeList.call(() => {
        iconCommandCenter.mount()
      })

      chart.trigger("updated")

      triggerInitialized()

      renderExtras(nodeList, nodes)
    }

    // Render extra non-essential elements
    const renderExtras = (nodeList, nodes) => {
      if (!isHierarchialView) return

      if (assistantsCanBeDisplayed()) {
        nodeList.filter(isTopLevel).each((d) => renderAssistants(d))
      }
      nodeList.filter(actsAsChartSectionHead).each(renderChartSectionLabel)
      renderChartSectionGroupings(nodes)

      nodeList.each(function () {
        maybeRenderChildCountElements(d3.select(this))
      })

      nodeList.each(function (d) {
        addPeopleDots(
          d3.select(this),
          (id, personId) => chart.trigger("click.switchPeople", id, personId),
          positioningHelper.getPeopleDotsYOffset(d),
        )
      })

      nodeList
        .filter((d) => d === root)
        .each(function (d) {
          if (options.displayMode === "three_level" && d === root) {
            chainOfCommand.addTo.call(
              this,
              d,
              positioningHelper,
              chart,
              getExtraChartSectionLabelHeight(),
            )
          }
        })

      colorCodeChartNodes(nodeList, colorCoder())
    }

    const hasSubPageNum = (d) => !!d.sub_page_num
    const hasParentPageNum = (d) => !!d.parent_page_num

    // For 3-level mode, the chart section label and grouping is drawn based on
    // chain-of-command if that's where the chart section head is. This returns
    // true for either a chart section head or for a node that has a chain of
    // command that includes a chart section head.
    const actsAsChartSectionHead = (d) => {
      if (!d) return false
      if (d.is_chart_section_head) return true
      if (options.displayMode === "three_level" && d.chain_of_command) {
        return !!chainOfCommand.findChartSectionHead(d)
      }

      return false
    }

    const updateLinks = function (nodes) {
      const links = tree.links(nodes.reverse())
      const linkList = polylineGroup.selectAll("path.link").data(links, (d) => d.target._id)

      linkList.exit().remove()

      linkList
        .enter()
        .append("path")
        .attr("class", "link")
        .attr("fill", "none")
        .attr("stroke", globals.linkColor)
        .attr("stroke-width", globals.linkStrokeWidth)
        .attr("stroke-linecap", "round")
        .attr("shape-rendering", "crispEdges")
        .attr("d", linkPointsForNode)
        .attr("opacity", 0)

      linkList
        .transition()
        .duration(duration)
        .attr("d", linkPointsForNode)
        .each("end", function () {
          d3.select(this).transition().duration(250).attr("opacity", 1)
        })

      return linkList
    }

    const drawAssistantsLink = function () {
      drawLink.apply(this, [this, "assistant-link"])
    }

    const drawLink = function (selection, linkClass = "link") {
      return this.append("polyline")
        .attr("fill", "transparent")
        .attr("stroke", globals.linkColor)
        .attr("stroke-width", globals.linkStrokeWidth)
        .attr("stroke-linecap", "round")
        .each(renderStrokeDashArray)
        .attr("opacity", () => {
          if (reinitializing) {
            return 0
          }
          if (!initialized) {
            return 1
          }

          return 0
        })
        .attr("class", linkClass)
        .attr("fill", "none")
        .attr("shape-rendering", "crispEdges")
    }

    const drawTempLink = function (source, target) {
      const links = []
      links.push({ source, target })

      const linkList = polylineGroup
        .selectAll("path.link.temporary")
        .data(links, (d) => `${d.target.id}_${d.source.id}`)

      linkList
        .enter()
        .append("path")
        .attr("fill", "transparent")
        .attr("stroke", globals.linkColor)
        .attr("stroke-width", globals.linkStrokeWidth)
        .each(renderStrokeDashArray)
        .attr("opacity", 1)
        .attr("class", "link temporary")
        .attr("fill", "none")
        .attr("shape-rendering", "crispEdges")
        .attr("stroke-linecap", "round")
        .attr("d", (d, idx, group) => linkPointsForNode(d, idx, group, totalVerticalSpacing()))
      linkList.exit().remove()
    }

    const handleNodeMouseEnter = function (d) {
      const actions = filterOptions(d)
      const grouping = $(this).closest("g.node").get(0)

      let actionsButttonVisible = false
      if (!d3.select(grouping).selectAll(".node-action-button")[0].length && actions.length) {
        hoverStateButtons.appendMenuButton.call(grouping, positioningHelper, toggleOptions)
        actionsButttonVisible = true
        grouping.showActionsWhenMouseLeaves = grouping.showActionsWhenMouseLeaves
          ? grouping.showActionsWhenMouseLeaves
          : false
        const openProfile = () => {
          chart.trigger("action.clickProfile", makeOrgChartNode(d))
        }
        if (d.klass === "Position" || (d.klass === "Company" && options.companyEditable)) {
          hoverStateButtons.appendProfileButton.call(
            grouping,
            positioningHelper,
            openProfile,
            actionsButttonVisible,
          )
        }
      }

      d3.select(grouping).selectAll("rect.card-inner-shadow").attr("fill", "transparent")
    }

    const handleNodeMouseLeave = function () {
      const event = d3.event

      if (
        event.relatedTarget &&
        $(event.relatedTarget).closest("g.node").get(0) === event.target.parentNode
      ) {
        return
      }
      const grouping = $(this).closest("g.node").get(0)

      d3.select(grouping)
        .selectAll("rect.card-inner-shadow")
        .attr("fill", "url(#node-background-linear-gradient)")

      if (!grouping.showActionsWhenMouseLeaves) {
        return $container
          .find(".node-action-button-group")
          .filter(function () {
            return this.parentNode.parentNode === d3.event.target.parentNode
          })
          .remove()
      }
    }

    const handleGroupingMouseLeave = function () {
      if (!this.showActionsWhenMouseLeaves) {
        return $container
          .find(".node-action-button-group")
          .filter(function () {
            return this.parentNode === d3.event.target
          })
          .remove()
      }
    }

    const setNodeVisibility = function (d) {
      if (options.displayMode !== "three_level") {
        return "visible"
      }

      if (d.depth <= 2) {
        return "visible"
      }

      return "hidden"
    }

    const attachDragListenerJustInTime = function (d) {
      const elem = d3.select(this).on("mouseenter", null)
      if (!options.dragAndDropEnabled) return elem

      const subordinateCheck = options.subordinateIds?.indexOf(d.id) >= 0
      const canDragAndDrop = gon.can_manage_chart || subordinateCheck
      return canDragAndDrop ? elem.call(dragListener) : elem
    }

    const renderChartSectionGroupings = function (nodes) {
      let allNodes = nodes
      if (options.displayMode === "three_level" && root.chain_of_command) {
        allNodes = [...allNodes, ...root.chain_of_command.slice(1)]
      }
      // Reject any chart section heads that aren't shown because their parent is
      // collapsed. Failure to do so results in an incorrect chart width, which
      // leads to issues when exporting with "fit to page" set to true. It doesn't
      // appear to lead to negative effects when viewing the chart normally.
      const chartSectionHeads = _(allNodes)
        .filter(actsAsChartSectionHead)
        .filter(({ parentId }) => !collapsedNodeIdLookup[parentId])
        .reverse()
      const grouping = chartSectionOverlayGroup
        .attr("visibility", () => {
          if (options.enableChartSections === true) {
            return "visible"
          }
          return "hidden"
        })
        .selectAll("g.grouping")
        .data(chartSectionHeads, (d) => d.id)

      grouping
        .enter()
        .append("g")
        .each(function (d) {
          const points = generateChartSectionGroupingPoints(d, positioningHelper.getNodeHeight())
          return d3
            .select(this)
            .append("path")
            .attr(
              "d",
              () =>
                _(points)
                  .map((point) => {
                    if (Number.isNaN(point.x) || Number.isNaN(point.y)) {
                      return `M 0 0`
                    }
                    if (points[0] === point) {
                      return `M ${point.x} ${point.y}`
                    }

                    return `L ${point.x} ${point.y}`
                  })
                  .reduce((d, lPoint) => `${d} ${lPoint}`, "") + " Z",
            )
            .attr("stroke", (d) => (options.enableChartSections && d.chart_section_color) || "none")
            .attr("stroke-width", "3")
            .attr("fill", (d) =>
              options.enableChartSections && d.chart_section_color ? d.chart_section_color : "none",
            )
            .attr("fill-opacity", ".1")
        })
        .attr("class", "grouping")
      d3.transition()
        .duration(duration)
        .each(() =>
          grouping.each(function (d) {
            const points = generateChartSectionGroupingPoints(d, positioningHelper.getNodeHeight())

            return d3
              .select(this)
              .select("path")
              .attr(
                "d",
                () =>
                  _(points)
                    .map((point) => {
                      if (Number.isNaN(point.x) || Number.isNaN(point.y)) {
                        return `M 0 0`
                      }
                      if (points[0] === point) {
                        return `M ${point.x} ${point.y}`
                      }
                      return `L ${point.x} ${point.y}`
                    })
                    .reduce((d, lPoint) => `${d} ${lPoint}`, "") + " Z",
              )
              .attr(
                "stroke",
                (d) => (options.enableChartSections && d.chart_section_color) || "none",
              )
              .attr("stroke-width", "3")
              .attr("fill", (d) =>
                options.enableChartSections && d.chart_section_color
                  ? d.chart_section_color
                  : "none",
              )
              .attr("fill-opacity", ".1")
          }),
        )
      grouping.exit().remove()
      return grouping.sort((a, b) => {
        const aGenerationCount = generationCount(a)
        const bGenerationCount = generationCount(b)
        if (aGenerationCount === bGenerationCount) {
          return 0
        }
        if (aGenerationCount < bGenerationCount) {
          return -1
        }
        return 1
      })
    }

    const calculateChartSectionLabelDimensions = (domNode) => {
      const numberOfCharacters = domNode.textContent.length
      const heightPerLine = 14.5
      const widthPerCharacter = 8
      const totalNeededWidth = numberOfCharacters * widthPerCharacter
      const linesToUse = domNode.dataset.wrappedIntoLines || 1
      let width = totalNeededWidth / linesToUse
      if (totalNeededWidth > maxWidth) {
        width = maxWidth
      }

      return {
        width,
        height: heightPerLine * linesToUse,
      }
    }

    const renderChartName = function () {
      if (options.displayChartName) {
        let x = 0

        if (options.displayMode === "cards") {
          x = viewerWidth / 2 - 200
        }

        svgGroup
          .append("text")
          .html(options.chartName)
          .attr("x", x)
          .attr("y", -80)
          .attr("text-anchor", "middle")
          .style(exportStyles.chart_name)
      }
    }

    const renderFooter = function () {
      let text = ""

      if (options.show_date) {
        text += window.moment().format("MMMM D, YYYY")
      }

      if (root && root.page_num && options.show_date) {
        text += " | "
      }

      if (root && root.page_num) {
        text += "Page " + root.page_num
      }

      if (text !== "") {
        baseSvg
          .append("text")
          .attr("class", "chart-footer")
          .html(text)
          .attr("x", 1300)
          .attr("y", 1000)
          .attr("text-anchor", "end")
          .style({
            "font-family": "Satoshi",
            "font-size": "9px",
          })
      }
    }

    const renderSubPageNum = function (d) {
      d3.select(this)
        .append("line")
        .attr("x1", 0)
        .attr("y1", positioningHelper.cardYPosition() + positioningHelper.getNodeHeight())
        .attr("x2", 0)
        .attr("y2", positioningHelper.cardYPosition() + positioningHelper.getNodeHeight() + 4)
        .attr("stroke-width", globals.linkStrokeWidth)
        .attr("stroke", globals.linkColor)

      d3.select(this)
        .append("rect")
        .attr("x", -20)
        .attr("y", positioningHelper.cardYPosition() + positioningHelper.getNodeHeight() + 4)
        .attr("rx", globals.nodeRadius)
        .attr("ry", globals.nodeRadius)
        .attr("height", 13)
        .attr("width", 40)
        .attr("class", "sub-page-number-rect")
        .style("fill", globals.cardColor)

      d3.select(this)
        .append("text")
        .attr("text-anchor", "middle")
        .html("Page " + d.sub_page_num)
        .attr("x", 0)
        .attr("y", positioningHelper.cardYPosition() + positioningHelper.getNodeHeight() + 13)
        .attr("class", "sub-page-number")
        .style(exportStyles.page_num)
    }

    const renderParentPageNum = function (d) {
      d3.select(this)
        .insert("line", ":first-child")
        .attr("x1", 0)
        .attr("y1", positioningHelper.nodeYPosition())
        .attr("x2", 0)
        .attr("y2", positioningHelper.nodeYPosition() - 40)
        .attr("stroke-width", globals.linkStrokeWidth)
        .attr("stroke", globals.linkColor)
        .attr("marker-end", "url(#parent-page-number-marker)")

      d3.select(this)
        .append("text")
        .attr("text-anchor", "middle")
        .html("Page " + d.parent_page_num)
        .attr("x", 0)
        .attr("y", positioningHelper.nodeYPosition() - 50)
        .attr("class", "parent-page-number")
        .style(exportStyles.parent_page_num)
    }

    const removeChartSectionLabel = function () {
      d3.select(this)
        .selectAll("text.chart-section-label, rect.chart-section-label, line.chart-section-label")
        .remove()
    }
    const renderChartSectionLabel = function (d) {
      removeChartSectionLabel.call(this)
      const verticalPadding = 4
      const horizontalPadding = 8

      if (d === root && !d.parent_page_num && d.is_chart_section_head) {
        d3.select(this)
          .append("line", ".card")
          .attr("class", "chart-section-label")
          .attr("x1", 0)
          .attr("y1", positioningHelper.nodeYPosition())
          .attr("x2", 0)
          .attr("y2", positioningHelper.nodeYPosition() - (getExtraChartSectionLabelHeight() + 5))
          .attr("stroke-width", globals.linkStrokeWidth)
          .attr("stroke", globals.linkColor)
      }

      const offsetFromBottomOfCard = 4
      const chainOfCommandOffset = chainOfCommand.chartSectionHeadVerticalOffset(
        d,
        options.verticalSpacing,
        getExtraChartSectionLabelHeight(),
      )
      const chartSectionPoint = {
        x: 0,
        y:
          -offsetFromBottomOfCard - offsetFromBottomOfCard - verticalPadding - chainOfCommandOffset,
      }

      const chartSectionText = d.chart_section
      const chartSectionTextElement = d3
        .select(this)
        .append("text")
        .attr("text-anchor", "middle")
        .html(chartSectionText)
        .attr("x", 0)
        .attr("y", chartSectionPoint.y)
        .attr("class", "chart-section-label")
        .style(exportStyles.chart_section_name)
        .style("fill", exportStyles.chart_section_name.fill)
      if (shouldWrap("chart_section")) {
        chartSectionTextElement.call(wrap, maxWidth, "chart_section_name")
      } else {
        chartSectionTextElement.call(truncateWithTooltip, "chart_section_name")
      }

      let textElement = d3.select(this).select("text.chart-section-label").node()
      if (d3.select(textElement.parentNode).attr("class") === "mouse-target") {
        textElement = textElement.parentNode
      }
      const boundingBox = calculateChartSectionLabelDimensions(textElement)
      const linesUsed = textElement.querySelectorAll("tspan").length || 1
      if (linesUsed > 1) {
        const extraLinesOffset = boundingBox.height / linesUsed
        // Adjust wrapped line texts to compensate for larger box size
        d3.select(this)
          .selectAll("text.chart-section-label")
          .attr("y", chartSectionPoint.y - extraLinesOffset + verticalPadding)
      }
      const width = boundingBox.width + horizontalPadding * 2
      const minHeight = 24
      const rectHeight = Math.max(boundingBox.height + verticalPadding * 2, minHeight)

      return d3
        .select(this)
        .insert("rect", ".card")
        .attr("width", width)
        .attr("height", rectHeight)
        .attr("x", -width / 2)
        .attr("y", -rectHeight - verticalPadding - chainOfCommandOffset)
        .attr("class", "chart-section-label")
        .attr("ry", globals.nodeRadius)
        .attr("rx", globals.nodeRadius)
        .attr("filter", "url(#node-shadow-elevation)")
        .style("fill", "rgba(237, 237, 242, 1)")
    }
    const generationCount = function (node) {
      let count = 0
      while (node.parent != null) {
        count += 1
        node = node.parent
      }
      return count
    }

    let triggerInitialized = null

    const resetInitialized = () => {
      initialized = false
      triggerInitialized = _.once(() => {
        if (!initialized) {
          if (options.orgchartLite) {
            return chart.trigger("initialized")
          }

          window.App.OrgChart.setChart(this)
          return chart.trigger("initialized")
        }
      })
    }

    const zoom = () => {
      $container.find(".node-action-button-group").remove()
      removeOptions()
      polylineGroup.selectAll("path.link").attr("stroke-width", globals.linkStrokeWidth)
      svgGroup.attr(
        "transform",
        "translate(" + d3.event.translate + ")scale(" + d3.event.scale + ")",
      )
      this.trigger("zoom")

      return handleZoom()
    }

    /*
     * @param {SVGGElement} element
     */
    const focusElement = function (element) {
      const nodeData = d3.select(element).data()[0]
      centerNode(nodeData, false, () => {
        effects.flashCard(element)
      })
    }

    const sortTree = () =>
      tree.sort((a, b) => {
        let aSort = null
        let bSort = null
        let aSort2 = null
        let bSort2 = null

        if (a.data?.positionAttributesWithEdits && b.data?.positionAttributesWithEdits) {
          aSort = a.data.positionAttributesWithEdits[options.sortField]
          bSort = b.data.positionAttributesWithEdits[options.sortField]
          aSort2 = a.data.positionAttributesWithEdits[options.secondarySortField]
          bSort2 = b.data.positionAttributesWithEdits[options.secondarySortField]
        } else {
          aSort = a[options.sortField]
          bSort = b[options.sortField]
          aSort2 = a[options.secondarySortField]
          bSort2 = b[options.secondarySortField]
        }

        if (options.sortField === "sort_order") {
          return d3[options.sortDirection](aSort, bSort)
        }

        // Use IDs to make sorting work deterministically.
        const partA = ("" + aSort + aSort2 + a.id).toLowerCase()
        const partB = ("" + bSort + bSort2 + b.id).toLowerCase()

        return d3[options.sortDirection](partA, partB)
      })

    const collapse = function (d) {
      if (statsHelpers.hasVisibleChildrenOrAssistants(d)) {
        collapsedNodeIdLookup[d.id] = true
        chart.trigger("node-collapsed", d.id)
        collapseChildrenAndAssistants(d)
        d._children.forEach(collapse)
      }
    }

    const expand = function (d) {
      if (statsHelpers.hasHiddenChildrenOrAssistants(d)) {
        delete collapsedNodeIdLookup[d.id]
        chart.trigger("node-expanded", d.id)
        expandChildrenAndAssistants(d)
      }
    }

    const collapseRecursively = function (d) {
      if (statsHelpers.hasVisibleChildrenOrAssistants(d)) {
        chart.trigger("node-collapsed", d.id)
        collapseChildrenAndAssistants(d)
      }
      if (statsHelpers.hasHiddenChildren(d)) {
        return d._children.forEach(collapseRecursively)
      }
    }

    // TODO: Can we collapse the children/descendants to thir actual collapse point?
    const collapseRecursivelyToDepth = (d, depth) => {
      if (statsHelpers.hasVisibleChildrenOrAssistants(d)) {
        if (d.depth >= depth) {
          // Collapses children of any nodes that are at the collapse depth or deeper.
          chart.trigger("node-collapsed", d.id)
          collapseChildrenAndAssistants(d)
        } else {
          // If the node is above the collapse depth, we still need to traverse any existing children.
          d.children.forEach((child) => collapseRecursivelyToDepth(child, depth))
        }
      }

      // Traverses any pre-existing hidden children or children that were collapsed in this step.
      if (statsHelpers.hasHiddenChildren(d)) {
        d._children.forEach((child) => collapseRecursivelyToDepth(child, depth))
      }
    }

    const expandRecursively = function (d) {
      if (statsHelpers.hasHiddenChildrenOrAssistants(d)) {
        chart.trigger("node-expanded", d.id)
        expandChildrenAndAssistants(d)
      }

      if (statsHelpers.hasVisibleChildren(d)) {
        return d.children.forEach(expandRecursively)
      }
    }

    const remove = function (node) {
      if (node === root) {
        if (node.parent_id) {
          return chart.trigger("action.display-chart-top", { id: node.parent_id })
        }
        return chart.trigger("action.display-full-chart")
      }
      if (node.parent.assistants) {
        node.parent.assistants = _.without(node.parent.assistants, node)
      }
      if (node.parent.children) {
        node.parent.children = _.without(node.parent.children, node)
      } else if (node.parent._children) {
        node.parent._children = _.without(node.parent._children, node)
      }
      const element = context.selectAll("g.node").filter((filtering) => filtering._id === node._id)
      element.remove()
      return update(node.parent, false)
    }

    const centerNode = function (source, forceVerticalCenter = false, callback = null) {
      const scale = zoomListener.scale()
      const nodeIsRoot = source === root
      const currentViewerElement = document.querySelector(selector)
      const currentViewerWidth = currentViewerElement?.offsetWidth ?? viewerWidth
      const currentViewerHeight = currentViewerElement?.offsetHeight ?? viewerHeight

      let offsetFromTop = nodeIsRoot ? 25 : 0

      if (actsAsChartSectionHead(source)) {
        offsetFromTop += getExtraChartSectionLabelHeight() + 10
      }

      if (source.klass === "Company") {
        offsetFromTop -=
          positioningHelper.getNodeHeight() - companyNodePositioningHelper.getNodeHeight()
      }

      // Scale and center the node horizontally
      const x = -source.x0 * scale + currentViewerWidth / 2

      // Translate the node to the center vertically, then apply the fixed offset from the top
      let y = 0

      if (nodeIsRoot && !forceVerticalCenter) {
        y = source.y0 + offsetFromTop * scale
      } else {
        y =
          -source.y0 * scale +
          currentViewerHeight / 2 -
          offsetFromTop -
          positioningHelper.getNodeHeight() / 2
      }

      // Offset to show some of the chain of commaind
      if (nodeIsRoot && source.chain_of_command && source.chain_of_command.length > 0) {
        const maxChainOfCommandToOffset = 3
        const chainOfCommandOffset =
          globals.chainOfCommand.height *
          Math.min(source.chain_of_command.length - 1, maxChainOfCommandToOffset)
        y += chainOfCommandOffset
      }

      moveChart([x, y], scale, false, duration).call(transitionsEnded, () => {
        if (callback) callback()
        if (options.loadAsync) {
          asyncSafeLoadVisible()
        }
      })
    }

    const toggleChildrenAndAssistants = function (d) {
      if (statsHelpers.hasVisibleAssistants(d)) {
        chart.trigger("node-collapsed", d.id)
        collapseAssistants(d)
      } else if (d._assistants) {
        chart.trigger("node-expanded", d.id)
        expandAssistants(d)
      }

      if (d.children) {
        chart.trigger("node-collapsed", d.id)
        collaspeChildren(d)
      } else if (d._children) {
        chart.trigger("node-expanded", d.id)
        expandChildren(d)
      }

      return d
    }

    const handleReportsRectClick = function () {
      return d3.select(this).on("click", (d) => {
        if (d3.event.defaultPrevented) {
          return
        }

        if (d.parent?.x && d.parent.x === d.x) {
          hideLinksForNode(d, true)
        }
        d = toggleChildrenAndAssistants(d)

        context
          .selectAll("g.node")
          .filter((node) => node._id === d._id)
          .each(function () {
            return moveToFront(this)
          })
        update(d)
      })
    }

    const moveToFront = function (element) {
      return element.parentNode.appendChild(element)
    }

    const removeOptions = function () {
      context.selectAll("g.node").each(function () {
        this.showActionsWhenMouseLeaves = false
        this.areOptionsShowing = false
      })

      return $container.find(".tools-panel").remove()
    }

    const filterOptions = function (d) {
      const isRoot = d === root
      const isPosition = d.type === "Position" || d.klass === "Position"
      const isSubordinate = !options.subordinateIds || options.subordinateIds.indexOf(d.id) >= 0
      const isLimitedAdminPosition = options.limitedAdminPositionIds?.includes(d.id)
      const canManageHierarchy = options.canManageHierarchy

      let { actions } = options
      if (isRoot) {
        actions = _.filter(actions, (action) => action.includeRoot)
      }
      if (!isPosition) {
        actions = _.filter(actions, (action) => !action.positionOnly)
      }
      if (!isRoot) {
        actions = _.filter(actions, (action) => !action.onlyRoot)
      }
      if (!isSubordinate) {
        actions = _.filter(
          actions,
          (action) =>
            !action.onlySubordinate ||
            (action.includeLimitedAdmin && isLimitedAdminPosition) ||
            gon.can_manage_chart,
        )
      }
      if (!gon.can_manage_chart && !canManageHierarchy) {
        actions = _.reject(actions, (action) =>
          ["add-position-by-parent", "delete", "duplicate", "add"].includes(action.action),
        )
      }
      if (d.is_assistant) {
        actions = _.filter(
          actions,
          (action) => action.action !== "add" && action.action !== "display-chart-top",
        )
      }
      if (!d.is_chart_section_head) {
        actions = _.filter(actions, (action) => action.action !== "delete-chart-section")
      }
      if (d.is_chart_section_head) {
        actions = _.filter(actions, (action) => action.action !== "add-chart-section")
      }

      return actions
    }

    const showOptions = function () {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const target = this
      d3.event.stopPropagation()
      const cardBoundingRect = target.parentNode.querySelector("rect.card").getBoundingClientRect()

      const $panel = $(`<div id="orgchart-tools-panel" class="tools-panel" />`)
      $container
        .find(".node-action-button-group")
        .filter(function () {
          return this.parentNode !== target.parentNode
        })
        .remove()
      removeOptions()
      const nodeData = d3.select(target).datum()
      const actions = filterOptions(nodeData)
      let currentActionsGroup = null
      const $divider = $("<hr>")
      target.parentNode.showActionsWhenMouseLeaves = true
      _.each(actions, (action) => {
        const node = makeOrgChartNode(nodeData)
        if (!action.condition || action.condition(node)) {
          const $a = $("<a>")

          const textContent =
            typeof action.text === "function" ? action.text.call(this, node) : action.text
          $a.text(textContent)
          if (action.isDisabled) {
            $a.addClass("disabled")
          } else {
            $a.on("click", () => chart.trigger("action." + action.action, node))
          }
          const actionGrouping = action.grouping || 0

          if (currentActionsGroup !== null && currentActionsGroup !== actionGrouping) {
            $panel.append($divider)
          }
          currentActionsGroup = actionGrouping
          if (action.secondaryAction) {
            const $div = $("<div>")
            $div.append($a)
            const $a2 = $('<a class="secondary-action">')
            $a2.text(action.secondaryText)
            $a2.on("click", () => chart.trigger("action." + action.secondaryAction, node))
            $div.append($a2)
            return $panel.append($div)
          }

          return $panel.append($a)
        }
      })
      const containerBoundingRect = document.querySelector(selector).getBoundingClientRect()
      $panel.css("left", -containerBoundingRect.x + cardBoundingRect.right + "px")
      const bottom =
        $container.height() +
        $container.offset().top -
        cardBoundingRect.top +
        10 * zoomListener.scale()
      const top =
        cardBoundingRect.top -
        $container.offset().top +
        10 * zoomListener.scale() +
        (hoverStateButtons.buttonWidth + hoverStateButtons.buttonEdgeOffset * 2) *
          zoomListener.scale()

      $container.append($panel)

      $panel.css("bottom", `${bottom}px`)
      const panelBoundingRect = $container
        .get(0)
        .querySelector(".tools-panel")
        .getBoundingClientRect()

      const spacingThreshold = 10
      if (panelBoundingRect.top < spacingThreshold) {
        $panel.css("bottom", "inherit")
        $panel.css("top", `${top}px`)
        $panel.addClass("inverted-position")
      }

      return $panel.addClass("positioned")
    }

    const hideOptions = () => removeOptions()

    const toggleOptions = function () {
      d3.event.preventDefault()
      const node = this.parentNode
      if (node.areOptionsShowing) {
        const $panel = $(".tools-panel")
        $panel.on("transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd", () => {
          hideOptions()
          node.areOptionsShowing = false
        })
        return $panel.removeClass("positioned")
      }

      showOptions.call(this)
      node.areOptionsShowing = true
    }

    const focus = function (id) {
      let selectedNode = null
      const nodes = allNodes()
      nodes.forEach((node) => {
        if (node.id === id) {
          selectedNode = node
        }
      })
      if (selectedNode) {
        return focusNode(selectedNode)
      }
    }

    let initialized = false
    let reinitializing = false
    const $container = $(selector)
    const context = d3.select(selector)
    if (!document.querySelector(selector)) {
      error('Element not found using selector: "' + selector + '"')
    }

    const dragHelper = new DragHelper(context, options, positioningHelper)

    const setExtraFieldPosition = function (i, extraLines, textAnchor, field) {
      return function () {
        const linePositionInfo = positioningHelper.fieldPositionInfo(field)
        let y = linePositionInfo.y ?? 0
        let x = 0
        if (textAnchor !== "middle") {
          if (options.showLabels) {
            x = maxWidth / 2
          } else {
            x = positioningHelper.measureTextInElement(this) / 2
          }

          if (this.nodeName === "svg") {
            y -= 10
            x -= 10
          }

          if (textAnchor === "start") {
            x = -x
          }
        }

        d3.select(this).selectAll("tspan").attr("x", x)
        return d3.select(this).attr("x", x).attr("y", y).attr("text-anchor", textAnchor)
      }
    }

    const linkPointsForNode = (d, _idx, _group, fixedVerticalSpacing = null) => {
      const sideLinkYPosition =
        positioningHelper.fieldPositionInfo("divider-top")?.y ??
        positioningHelper.getNodeHeight() / 2
      if (options.displayMode === "three_level") {
        if (d.target.depth === 2) {
          return nodeLinkGenerators.linkPathForThirdLevelNode(
            d,
            positioningHelper.getNodeHeightInContext(d),
            sideLinkYPosition,
            nodeHeightWithVerticalSpacing(),
          )
        }
        if (d.target.depth > 2) {
          return
        }
      }
      const isCondensedTreeBranch =
        options.displayMode === "tree" &&
        options.condensed &&
        statsHelpers.numberOfGrandchildren(d.source) === 0 &&
        d.target.parent &&
        d.source.children.length > 1

      if (isCondensedTreeBranch) {
        return nodeLinkGenerators.linkPathForCondensedTreeBranch(
          d,
          positioningHelper.getNodeHeight(),
          sideLinkYPosition,
          nodeHeightWithVerticalSpacing(),
        )
      }

      return nodeLinkGenerators.linkPathForNode(
        d,
        positioningHelper.getNodeHeight(),
        getAssistantVerticalSpacing() - positioningHelper.statsBarHeight(),
        fixedVerticalSpacing,
      )
    }

    const bindClickNameHandler = function () {
      return d3.select(this).on("mouseup", (d) => {
        if (!d3.event.defaultPrevented && !dragContinued && !$(this).hasClass("open")) {
          return chart.trigger("click.name", makeOrgChartNode(d))
        }
      })
    }

    const displayTopOnClick = function () {
      if (options.displayMode !== "three_level") {
        return
      }
      return d3.select(this).on("click", (d) => {
        // Prevents this action if the user clicked the
        // "Fill Position" button on the node.
        if (this.classList.contains("fill")) return

        if (!d3.event.defaultPrevented && root !== d) {
          if (d.klass === "Position" && !d.is_assistant) {
            return chart.trigger("action.display-chart-top", d)
          }
          if (d.klass === "Company") {
            return chart.trigger("action.display-full-chart")
          }
        }
      })
    }

    const shouldWrap = function (fieldName) {
      return _.includes(wrappedFields(), fieldName)
    }

    const truncateWrappedField = function (text, value, fieldName) {
      const extraLinesNeeded = calculateLineCount(value) - 1
      if (!(extraLinesNeeded > 0)) {
        return
      }
      const previousExtraLinesNeeded = extraLinesMap[fieldName] || 0
      if (!(previousExtraLinesNeeded < extraLinesNeeded)) {
        return
      }
      const wrappedLines = text.selectAll("tspan")[0]
      _.each(wrappedLines.slice(previousExtraLinesNeeded + 1), (tspan) => tspan.remove())
      const lastLine = text.select("tspan:last-child")
      const lastLineText = lastLine.text()
      const newText = lastLineText.slice(0, lastLineText.length - "..".length) + ".."

      lastLine.text(newText)
    }

    const renderCard = function (d) {
      d3.select(this)
        .attr("class", "card")
        .attr("rx", globals.nodeRadius)
        .attr("ry", globals.nodeRadius)
        .attr("fill", "rgb(255, 255, 255)")
        .attr("filter", "url(#node-shadow-elevation)")
        .style(exportStyles.card)

      if (d.card_color && options.enableSecondaryFields) {
        d3.select(this).style("fill", d.card_color)
      }
    }

    const renderCardShadow = function () {
      d3.select(this)
        .attr("class", "card-inner-shadow")
        .attr("rx", globals.nodeRadius)
        .attr("ry", globals.nodeRadius)
        .attr("fill", "url(#node-background-linear-gradient)")
    }

    const companyNodeFieldYOffset = () =>
      positioningHelper.getNodeHeight() - companyNodePositioningHelper.getNodeHeight()

    const renderCardPosition = function () {
      return d3
        .select(this)
        .attr("x", -(nodeWidth / 2))
        .attr("y", () => positioningHelper.cardYPosition())
    }

    const renderCardDimensions = function () {
      return d3
        .select(this)
        .attr("width", nodeWidth)
        .attr("height", (d) => {
          if (d.klass === "Company") {
            return companyNodePositioningHelper.getNodeHeight()
          }
          return positioningHelper.getNodeHeightInContext(d)
        })
    }

    const renderTextField = function (field, value, className, fill) {
      if (_.isArray(value)) {
        value = value.join(", ")
      }
      value = _.unescape(value)
      const element = d3.select(field)
      return element
        .attr("class", className)
        .text(value)
        .style(exportStyles.text)
        .style("fill", fill)
        .attr("width", maxWidth)
    }

    // The "name" <text> element is a special field and has a set position.
    const setNamePosition = function (d) {
      let linePositionInfo
      if (d.klass === "Company") {
        linePositionInfo = companyNodePositioningHelper.fieldPositionInfo("name")
      } else {
        linePositionInfo = positioningHelper.fieldPositionInfo("name")
      }
      return d3
        .select(this)
        .attr("x", -positioningHelper.measureTextInElement(this, "name") / 2 - 2)
        .attr("y", linePositionInfo.y)
    }

    const renderNameText = function (d) {
      const name =
        d.name ||
        d.data?.positionAttributesWithEdits?.filled_by_name ||
        d.data?.name ||
        _.compact([d.first_name, d.last_name]).join(" ")
      if (name) {
        renderTextField(this, name, "name", colorCoder().codeTextColor(d))
        return d3
          .select(this)
          .classed(emptyPositionTextClass, false)
          .classed("fill-neutral-100", true)
          .style(exportStyles.name)
      }
      return d3
        .select(this)
        .text(emptyPositionText)
        .classed("name", false)
        .classed(emptyPositionTextClass, true)
        .style(exportStyles.name)
        .style("fill", colorCoder().codeTextColor(d))
    }

    const wrapOrTruncateName = function (d) {
      if ((d === root && d.klass === "ChartSection") || shouldWrap("name")) {
        d3.select(this).call(wrap, maxWidth, "name")
      } else {
        d3.select(this).call(truncateWithTooltip, "name")
      }
    }

    const setTitlePosition = function () {
      const titleFieldInfo = positioningHelper.fieldPositionInfo("title")

      return d3
        .select(this)
        .attr("x", -positioningHelper.measureTextInElement(this, "title_text") / 2 - 2)
        .attr("y", titleFieldInfo.y)
    }

    const renderTitleText = function (d) {
      const value = d.title || d.data?.positionAttributesWithEdits?.title?.label || ""
      const titleTextElement = renderTextField(this, value, "title", colorCoder().codeTextColor(d))
      return titleTextElement.classed("fill-neutral-80", true).style(exportStyles.title_text)
    }

    const wrapOrTruncateTitle = function (d) {
      if ((d === root && d.klass === "ChartSection") || shouldWrap("title")) {
        d3.select(this).call(wrap, maxWidth)
        // Since you can add a position after the chart is loaded, the title of a
        // new position may be longer than the longest that was loaded with the
        // chart. In that case, the new title is truncated rather than resizing
        // everything.
        if (d.title && !_.includes(getAggregate("title"), d.title)) {
          return d3.select(this).call(truncateWrappedField, d.title, "title")
        }
      } else {
        d3.select(this).call(truncateWithTooltip)
      }
    }

    const renderStrokeDashArray = function () {
      return d3.select(this).attr("stroke-dasharray", (d) => {
        if (d.target.type === "secondary") {
          return "6"
        }
        return "0"
      })
    }
    let root
    let dataArr
    const { maxWidth, nodeWidth } = globals
    let dragStarted = false
    let dragContinued = false
    let draggingNode = null
    let selectedParentNode = null

    const wrappedFields = () => {
      if (!options.enableTextwrap) {
        return []
      }
      return ["title", "chart_section"]
    }

    let internalIdCounter = 0
    let duration = 0
    let viewerWidth = options.width
    let viewerHeight = options.height
    let treeMethod
    if (options.displayMode === "tree" && options.condensed) {
      treeMethod = "condensedTree"
    } else if (options.displayMode === "cards") {
      treeMethod = "list"
    } else {
      treeMethod = "tree"
    }

    const walkTree = function (rootOrRoots, visitor) {
      const toVisit = Array.isArray(rootOrRoots) ? rootOrRoots : [rootOrRoots]
      while (toVisit.length > 0) {
        const current = toVisit.shift()
        if (current.children) current.children.forEach((childNode) => toVisit.push(childNode))

        visitor(current)
      }

      return rootOrRoots
    }

    const collapseAssistants = (node) => {
      if (statsHelpers.hasVisibleAssistants(node)) {
        node._assistants = node.assistants
        // It's important to set `assistants` here to an empty array
        // so the assistants exit the chart properly in #renderAssistants.
        node.assistants = []
      }
    }

    const collaspeChildren = (node) => {
      if (statsHelpers.hasVisibleChildren(node)) {
        node._children = node.children
        node.children = null
      }
    }

    const expandAssistants = (node) => {
      if (statsHelpers.hasHiddenAssistants(node)) {
        node.assistants = node._assistants
        node._assistants = null
      }
    }

    const expandChildren = (node) => {
      if (statsHelpers.hasHiddenChildren(node)) {
        node.children = node._children
        node._children = null
      }
    }

    const collapseChildrenAndAssistants = (node) => {
      collapseAssistants(node)
      collaspeChildren(node)
    }

    const expandChildrenAndAssistants = (node) => {
      expandAssistants(node)
      expandChildren(node)
    }

    const shakeNodeIfCollapsed = function (node) {
      // Clear `children` and `assistants` if this node is collapsed--this is what prevents the
      // tree from rendering its descendants. Set to `_children` and `_assistants` so `expand` can
      // restore `children`.
      if (collapsedNodeIdLookup[node.id]) collapseChildrenAndAssistants(node)
    }

    /**
     * Performs a walk over the initial tree of data and ensures each node has
     * `_id`, `parent`, `children`, and/or `_children` populated based on chart
     * state.
     */
    const prepareTreeForInitialRender = (rootNodeOrNodes) =>
      walkTree(rootNodeOrNodes, (node) => {
        node._id = node._id || ++internalIdCounter
        if (node.children) node.children.forEach((childNode) => (childNode.parent = node))
        shakeNodeIfCollapsed(node)
      })

    let tree = d3.layout[treeMethod]().nodeSize([globals.nodeWidth + globals.nodeMarginX])
    if (options.displayMode !== "cards") {
      tree.separation(d3TreeSeparation)
    }
    const zoomListener = d3.behavior
      .zoom()
      .scaleExtent([options.minimumZoom, options.maximumZoom])
      .scale(options.initialZoom)
      .on("zoom", zoom, { capture: true })
    viewerHeight = $(selector).height()
    viewerWidth = $(selector).width()
    const baseSvg = context
      .append("svg")
      .attr("width", viewerWidth)
      .attr("height", viewerHeight)
      .attr("class", options.displayMode)
      .attr("xmlns:xlink", "http://www.w3.org/1999/xlink")

    behavior.attachResizeObserver(baseSvg, selector)

    // Helper for moving/scaling the chart in a unified way.
    // chart.getCurrentPosition() uses the zoomListener to grab the chart's
    // current position, which is used to maintain chart positioning. Using this
    // helper ensures we're calling the zoomListener correctly.
    const moveChart = function (translate, newScale = null, center = false, moveDuration = 0) {
      if (newScale) zoomListener.scale(newScale)
      if (center) zoomListener.center(null)

      zoomListener.translate(translate)

      return baseSvg.transition().duration(moveDuration).call(zoomListener.event)
    }

    const pan = function () {
      const baseG = d3.select("g")
      const dimensions = baseG.node().getBBox()
      const currentTranslate = zoomListener.translate()
      const currentScale = zoomListener.scale()

      if (d3.event.ctrlKey) {
        let newScale = 2 ** (-d3.event.deltaY * 0.002) * currentScale[0]
        newScale = d3.max([options.minimumZoom, d3.min([options.maximumZoom, newScale])])
        const minX = viewerWidth - (dimensions.width + dimensions.x) * newScale - 20
        const maxX = -dimensions.x * newScale + 20
        const minY = viewerHeight - dimensions.height * newScale - 20
        const maxY = 130
        const dx = d3.max([minX, d3.min([maxX, currentTranslate[0]])])
        const dy = d3.max([minY, d3.min([maxY, currentTranslate[1]])])
        moveChart([dx, dy], newScale)
      } else {
        moveChart(
          [currentTranslate[0] - d3.event.deltaX, currentTranslate[1] - d3.event.deltaY],
          currentScale,
        )
      }

      d3.event.preventDefault()
      return handleZoom()
    }

    if (options.panOnScroll) {
      baseSvg
        .call(zoomListener)
        .on("touchstart", (ev) => ev?.preventDefault(), { capture: true, passive: false })
        .on("touchend", (ev) => ev?.preventDefault(), { capture: true, passive: false })
        .on("wheel.zoom", pan, { capture: true, passive: false })
        .on("mousewheel.zoom", pan, { capture: true, passive: false })
        .on("dblclick.zoom", null)
    } else {
      baseSvg.call(zoomListener).on("dblclick.zoom", null)
    }

    baseSvg.classed("drag-and-drop-enabled", options.dragAndDropEnabled)

    // This invisible rectangle is added here as a fix for an issue on Safari.
    // Safari doesn't fire any pointer events for areas of an SVG that aren't
    // filled with something.
    baseSvg
      .append("rect")
      .attr("fill", "transparent")
      .attr("class", "safari-pointer-events-fix")
      .attr("width", viewerWidth)
      .attr("height", viewerHeight)

    let svgGroup = baseSvg.append("g")
    const polylineGroup = svgGroup.append("g").attr("class", "polyline-groupings")
    svgGroup.append("g").attr("class", "groupings")
    const chartSectionOverlayGroup = svgGroup.append("g").attr("class", "chart-section-stuff-skrrt")
    const tooltipHandler = new TooltipHandler()
    const emptyPositionText = options.emptyPositionText || "Empty Position"
    const emptyPositionTextClass = options.emptyPositionTextClass || "empty"

    const dragListener = d3.behavior.drag()
    const overDragThreshold = function (d) {
      const dx = d3.event.sourceEvent.clientX - d.startX
      const dy = d3.event.sourceEvent.clientY - d.startY
      const distance2 = dx * dx + dy * dy
      const threshold2 = 100

      return distance2 > threshold2
    }

    dragListener.on(
      "dragstart",
      (d) => {
        d.startX = d3.event.sourceEvent.clientX
        d.startY = d3.event.sourceEvent.clientY
        draggingNode = null
        dragStarted = true
        selectedParentNode = null
        return d3.event.sourceEvent.stopPropagation()
      },
      { passive: true },
    )

    dragListener.on("drag", function (d) {
      draggingNode = d
      if (!overDragThreshold(d)) {
        return
      }

      const shouldShift = Math.abs(d3.event.dy) + Math.abs(d3.event.dx) < 2
      // Selecting all shifted nodes here before clearing the dropzones
      const shiftedNodeIds = context
        .selectAll("g.node.shifted")
        .data()
        .map((shifted) => shifted._id)
      if (shouldShift) {
        dragHelper.clearDropzones()
      }
      selectedParentNode = null
      let selection = context.selectAll(".selected")
      if (selection[0].indexOf(this) === -1) {
        selection.classed("selected", false)
        selection = d3.select(this)
        selection.classed("selected", true)
      }
      selection.attr("transform", (d) => {
        d.x0 += d3.event.dx
        d.y0 += d3.event.dy
        return "translate(" + [d.x0, d.y0] + ")"
      })
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const draggingElement = this
      moveToFront(draggingElement)
      if (dragStarted) {
        const nodes = tree.nodes(draggingNode)
        if (nodes.length > 1) {
          const links = tree.links(nodes)
          polylineGroup
            .selectAll("path.link")
            .data(links, (d) => d.target._id)
            .attr("visibility", "hidden")
          if (statsHelpers.hasVisibleAssistants(d) || d === root) {
            svgGroup.selectAll("g.node.assistant").attr("visibility", "hidden")
            polylineGroup.selectAll("polyline.assistant-link").attr("visibility", "hidden")
          }
          svgGroup
            .selectAll("g.node")
            .data(nodes, (d) => d._id)
            .filter((d) => {
              if (d._id === draggingNode._id) {
                return false
              }
              return true
            })
            .attr("visibility", "hidden")
        }
        // Remove links that directly point to the dragging node. These are re-rendered on "drop"
        polylineGroup
          .selectAll("path.link, polyline.assistant-link")
          .filter((d) => d.target._id === draggingNode._id)
          .remove()
        dragStarted = false
        dragContinued = true
      }
      dragHelper.removeTempLinks()
      polylineGroup
        .selectAll("path.link:not(.temporary)")
        .filter((d) => shiftedNodeIds.includes(d.target._id))
        .transition()
        .duration(duration)
        .attr("points", linkPointsForNode)
      // eslint-disable-next-line no-undef
      App.safeRequestAnimationFrame(() => {
        const selectedParent = dragHelper.getTargetNodeByElement(draggingElement)
        if (selectedParent.node()) {
          dragHelper.clearDropzones()
          selectedParent.classed("dropzone", true)
          selectedParentNode = selectedParent.datum()
        } else {
          let siblingFilter = dragHelper.closestNodeInRow(draggingElement)
          let siblingElement = siblingFilter.node()
          let siblingNode = null
          if (siblingElement) {
            siblingNode = siblingFilter.datum()
          }

          if (treeMethod === "condensedTree") {
            const isSiblingInCondensedTree =
              siblingNode &&
              statsHelpers.numberOfGrandchildren(siblingNode.parent) === 0 &&
              statsHelpers.numberOfChildren(siblingNode.parent) > 1

            // If the closest row node is within a condensed tree layout, we only
            // want to allow dropping into that layout through the leafNode flow
            // below.
            if (isSiblingInCondensedTree) {
              siblingNode = null
              siblingElement = null
            }

            const leafFilter = dragHelper.condensedLeafNode(draggingElement)
            const leafNode = leafFilter?.datum()
            const leafNodeParent = leafNode?.parent
            const isLeafInCondensedTree =
              leafNodeParent &&
              statsHelpers.numberOfGrandchildren(leafNodeParent) === 0 &&
              statsHelpers.numberOfChildren(leafNodeParent) > 1

            if (isLeafInCondensedTree) {
              selectedParentNode = leafNodeParent
              return drawTempLink(selectedParentNode, draggingNode)
            }
          }

          if (
            options.displayMode === "three_level" &&
            (!siblingElement || siblingNode.depth === 2)
          ) {
            siblingFilter = dragHelper.closestNodeInColumn(draggingElement)
            siblingElement = siblingFilter?.node()

            if (!siblingElement) {
              return
            }

            siblingNode = siblingFilter.datum()
            selectedParentNode = siblingNode.parent
            if (draggingNode.y0 < selectedParentNode.y0) {
              selectedParentNode = null
              return
            }

            draggingNode.depth = 2
            drawTempLink(selectedParentNode, draggingNode)
          } else if (siblingElement) {
            if (siblingNode.is_assistant) {
              selectedParentNode = null
              return
            }

            selectedParentNode = siblingNode.parent
            if (selectedParentNode && selectedParentNode.children) {
              const childrenIds = selectedParentNode.children
                .map((c) => c.id)
                .filter((a) => a !== draggingNode.id)
              const nodesBefore = context
                .selectAll("g.node")
                .filter((n) => childrenIds.includes(n.id) && draggingNode.x0 > n.x)
              const nodesAfter = d3
                .selectAll("g.node")
                .filter((n) => childrenIds.includes(n.id) && draggingNode.x0 < n.x)

              if (
                Math.abs(draggingNode.x0 - siblingNode.x0) > 200 &&
                ((draggingNode.x0 > siblingNode.x0 && nodesAfter.size() === 0) ||
                  (draggingNode.x0 < siblingNode.x0 && nodesBefore.size() === 0))
              ) {
                selectedParentNode = null
                return
              }

              if (
                dragHelper.intersectAreaByTranslate(draggingElement, siblingElement) > 0 &&
                nodesBefore.size() > 0 &&
                nodesAfter.size() > 0
              ) {
                if (shouldShift) {
                  nodesBefore
                    .classed("shifted", true)
                    .transition()
                    .duration(duration)
                    .attr("transform", (d) => {
                      d.x0 = d.x - 20
                      return `translate(${d.x0}, ${d.y0})`
                    })
                  nodesAfter
                    .classed("shifted", true)
                    .transition()
                    .duration(duration)
                    .attr("transform", (d) => {
                      d.x0 = d.x + 20
                      return `translate(${d.x0}, ${d.y0})`
                    })
                }
                const idsList = nodesBefore
                  .data()
                  .map((bef) => bef._id)
                  .concat(nodesAfter.data().map((after) => after._id))
                polylineGroup
                  .selectAll("path.link:not(.temporary)")
                  .filter((d) => idsList.includes(d.target._id))
                  .transition()
                  .duration(duration)
                  .attr("d", linkPointsForNode)
              }

              draggingNode.depth = siblingNode
              drawTempLink(selectedParentNode, draggingNode)
            }
          }
        }
      })

      return d3.event.sourceEvent.stopPropagation()
    })

    dragListener.on("dragend", function (d) {
      dragStarted = false
      if (!overDragThreshold(d)) {
        return
      }

      if (dragContinued) {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const draggingElement = this
        d3.select(draggingElement).classed("selected", false)
        dragHelper.clearDropzones()

        if (
          selectedParentNode &&
          draggingNode &&
          draggingNode.parent &&
          (draggingNode.parent.children || draggingNode.parent.assistants)
        ) {
          const previousParent = draggingNode.parent

          // Set the new parent on the dragging node.
          draggingNode.parent_id = selectedParentNode.id

          if (previousParent !== selectedParentNode) {
            const nodeTypeOnPreviousParent =
              draggingNode.is_assistant && statsHelpers.hasVisibleAssistants(previousParent)
                ? "assistants"
                : "children"
            const index = previousParent[nodeTypeOnPreviousParent].indexOf(draggingNode)
            const nodeExistsOnPreviousParent = index > -1

            if (nodeExistsOnPreviousParent) {
              previousParent[nodeTypeOnPreviousParent].splice(index, 1)
              updateNode(previousParent)
            }

            expand(selectedParentNode)

            // We only store assistants in the `assistants` property when assistants can be displayed.
            const nodeTypeOnSelectedParent =
              draggingNode.is_assistant && assistantsCanBeDisplayedForNode(selectedParentNode)
                ? "assistants"
                : "children"

            if (selectedParentNode[nodeTypeOnSelectedParent]) {
              selectedParentNode[nodeTypeOnSelectedParent].push(draggingNode)
            } else {
              selectedParentNode[nodeTypeOnSelectedParent] = [draggingNode]
            }
          }

          if (options.sortField === "sort_order") {
            if (
              treeMethod === "condensedTree" &&
              statsHelpers.numberOfGrandchildren(selectedParentNode) === 0 &&
              statsHelpers.numberOfChildren(selectedParentNode) > 2
            ) {
              dragHelper.sortCondensedTreeNodesAfterDrop(selectedParentNode, draggingNode)
            } else if (
              treeMethod === "condensedTree" &&
              !!previousParent?.children?.find((child) => child.id === selectedParentNode.id) &&
              statsHelpers.numberOfChildren(selectedParentNode) === 1 &&
              statsHelpers.numberOfGrandchildren(previousParent) === 1
            ) {
              // In this case, we're transitioning from a condensed tree layout to
              // a classic tree, so we want to preserve the sort order of the
              // condensed tree rather than reorder based on x position.
              draggingNode.sort_order = 0
              dragHelper.sortNodesByOrder(previousParent.children)
            } else if (options.displayMode === "three_level" && selectedParentNode.depth === 1) {
              selectedParentNode.children
                .sort((a, b) => d3.ascending(a.y0, b.y0))
                .forEach((child, index) => (child.sort_order = index))
            } else {
              dragHelper.sortClassicTreeNodesAfterDrop(selectedParentNode, previousParent)
            }
          }

          dragHelper.removeTempLinks()
          update(d, false)
          updateNode(selectedParentNode)
          const previousParentNode = makeOrgChartNode(previousParent)
          const node = makeOrgChartNode(d)

          if (previousParent !== selectedParentNode) {
            previousParentNode.updateCountForSelfAndAncestors(-node.getChildCountDifference())

            node.get("parent").updateCountForSelfAndAncestors(node.getChildCountDifference())

            if (d.parent.parent) {
              if (
                statsHelpers.numberOfGrandchildren(d.parent.parent) === 1 &&
                d.parent.parent !== previousParent.parent
              ) {
                hideLinksForNode(d.parent.parent)
              }
            }

            if (previousParent.parent) {
              if (statsHelpers.numberOfGrandchildren(previousParent.parent) === 0) {
                hideLinksForNode(previousParent.parent)
              }
            }
          }

          chart.trigger("drop", node, node.get("parent"), previousParentNode)
          sortTree()
        }
        dragContinued = false

        // Set descendant nodes to visible
        const nodes = tree.nodes(d)
        svgGroup
          .selectAll("g.node")
          .data(nodes, (d) => d._id)
          .filter((d) => {
            if (d._id === draggingNode?._id) {
              return false
            }
            return true
          })
          .attr("visibility", "visible")

        // Set descendant links to visible
        const links = tree.links(nodes)
        polylineGroup
          .selectAll("path.link")
          .data(links, (d) => d.target._id)
          .attr("visibility", "visible")

        // set any assistants to visible
        if (statsHelpers.hasVisibleAssistants(d) || d === root) {
          svgGroup.selectAll("g.node.assistant").attr("visibility", "visible")
          polylineGroup.selectAll("polyline.assistant-link").attr("visibility", "visible")
        }

        update(d, false)
      }
      return chart.trigger("expand")
    })

    const hideLinksForNode = function (node, includeSelf = false) {
      const nodes = includeSelf ? tree.nodes(node) : tree.nodes(node).splice(1)
      const _ids = _.map(nodes, (node) => node._id)

      return polylineGroup
        .selectAll("path.link")
        .filter((d) => _.indexOf(_ids, d.target._id) !== -1)
        .attr("opacity", 0)
    }

    const matchToPreviousInternalIds = function (data) {
      const nodes = tree.nodes(root).reverse()
      const flatData = tree.nodes(data).reverse()
      flatData.map((d) => {
        const previousNode = _.find(nodes, (node) => node.id === d.id && node.klass === d.klass)
        if (previousNode) {
          d._id = previousNode._id
          d.loaded = false
        } else {
          d._id = ++internalIdCounter
        }
        return d
      })
      return flatData
    }

    // This replaces the data in the root node with a new set. It matches data
    // from the previous root to the new one so common nodes can be used and
    // transitioned without the need to rebuild them
    const replaceRoot = (data, centerRoot = true) => {
      resetInitialized()
      reinitializing = true

      chart.once("initialized", () => {
        initialized = true
        reinitializing = false
      })

      if (isHierarchialView && !root) {
        error("Replace root called on an empty chart")
      }
      d3.selectAll("path.link, polyline.assistant-link").remove()
      d3.selectAll("g.chain-of-command").remove()
      d3.selectAll("g.assistant").remove()
      d3.selectAll("line.link").remove()
      d3.selectAll("text.chart-section-label, rect.chart-section-label").remove()
      d3.selectAll(".orgchart-tooltip").style({ visibility: "hidden" })
      d3.selectAll("g.empty").remove()

      if (options.displayMode === "cards") {
        d3.selectAll("g.node").remove()
        if (data.length > 0) {
          dataArr = data
          determineShowRole(data)
          calculateExtraLines(data[0])
          positioningHelper = new NodePositioningHelper(getFields(), options, extraLinesMap)
          tree.nodeSize([globals.nodeWidth + globals.nodeMarginX, nodeHeightWithVerticalSpacing()])
          update(data, false)
        } else {
          renderEmptyList()
        }
      } else {
        matchToPreviousInternalIds(data)
        root = data
        calculateExtraLines(root)
        positioningHelper = new NodePositioningHelper(getFields(), options, extraLinesMap)
        root.x0 = viewerHeight / 2
        root.y0 = 0
        update(root, false)
      }

      if (isHierarchialView && centerRoot) {
        centerNode(root)
      }
    }

    let dataLoader
    // `dataLoader` is not defined until after the first call to initializeTree.
    // Some user interactions trigger `dataLoader.loadVisible`, and those
    // interactions may occur before this is initialized. This provides a safe way
    // to call the function while waiting until it's loaded.
    const asyncSafeLoadVisible = () => (dataLoader ? dataLoader.loadVisible() : false)
    const initializeTree = (data) => {
      resetInitialized()
      renderChartName()
      if (options.displayMode === "cards") {
        dataArr = data
        calculateExtraLines(data[0])
        positioningHelper = new NodePositioningHelper(getFields(), options, extraLinesMap)
        determineShowRole(data)
        tree.nodeSize([globals.nodeWidth + globals.nodeMarginX, nodeHeightWithVerticalSpacing()])
      } else {
        // 'Company' klass is in the data hash if this is coming from HCP Org Chart
        // Reassign it here to make it available to the rest of the code.
        if (data.data?.klass) data.klass = data.data.klass
        root = data
        root.x0 = viewerHeight / 2
        root.y0 = 0
        calculateExtraLines(root)
        positioningHelper = new NodePositioningHelper(getFields(), options, extraLinesMap)
      }

      if (options.loadAsync) {
        dataLoader = new PositionDataLoader(baseSvg, this, options.positionsEndpoint)
      }
      sortTree()

      // SVG <defs>
      defineChartDefs(baseSvg, positioningHelper.getNodeHeight())

      // defineParentPageNumTriangleMarker()

      chart.once("initialized", () => {
        initialized = true
        // Duration is set to 0 until here when it's initialized to avoid
        // animating the chart before it's rendered for the first time.
        duration = options.animationDuration
      })
      renderFooter()

      if (options.displayMode === "cards") {
        if (data.length > 0) {
          update(data)
        } else {
          renderEmptyList()
        }
      } else {
        update(prepareTreeForInitialRender(root))
      }
    }

    const renderEmptyList = () => {
      const emptyGroup = svgGroup
        .append("g")
        .classed("empty", true)
        .attr("transform", `translate(${(viewerWidth - 400) / 2},0)`)
      emptyGroup
        .append("image")
        .attr("href", "/static-assets/empty-list.png")
        .attr("x", -70)
        .attr("y", 0)
      emptyGroup
        .append("text")
        .text("Looks like your list doesn't have any people yet!")
        .attr("x", -150)
        .attr("y", 160)
        .style({
          fill: "#333",
          "font-weight": "bold",
        })

      if (window.gon.can_manage_chart) {
        emptyGroup
          .append("text")
          .text("Manage your list to start adding people.")
          .attr("x", -110)
          .attr("y", 185)
          .style({
            fill: "#333",
            "font-size": "12px",
          })
        const manageLink = emptyGroup.append("a").classed("btn--large btn--primary", true)
        manageLink.on("click", () => {
          $(".org-chart-toolbar .manage-list")[0].click()
        })
        manageLink
          .append("rect")
          .attr("rx", globals.nodeRadius)
          .attr("ry", globals.nodeRadius)
          .attr("x", -52)
          .attr("y", 210)
          .attr("width", 104)
          .attr("height", 40)
          .style("fill", "rgba(27, 98, 255, 1)")
        manageLink
          .append("text")
          .text("Manage List")
          .attr("x", -35)
          .attr("y", 235)
          .style("fill", "#fff")
      }

      return triggerInitialized()
    }

    // This is debounced because the initial load calls update a few times. By
    // debouncing, the initial fetch is delayed until the chart is centered
    // (something that affects the nodes that should be loaded).
    const fetchInitialPositionData = _.debounce(
      () => dataLoader && dataLoader.loadVisible(),
      250,
      true,
    )

    // Zoom handlers are throttled/executed immediately so positions are loaded
    // as soon as possible as you drag/zoom. This function is effectively a no-op
    // while the chart isn't initialized. This helps prevent some weirdness if the
    // user accidentally pans while the chart is being loaded (this can shift the
    // view to a dead zone).
    const handleZoom = _.throttle(() => {
      if (!initialized) return
      if (options.loadAsync) asyncSafeLoadVisible()

      chart.trigger("coordinate-change", {
        zoom: chart.getZoom(),
        chart_position: chart.getCurrentPosition(),
      })
    }, 250)

    // Determine whether any of the list people have roles so that we can add
    // the extra height to nodes
    const determineShowRole = function (data) {
      positioningHelper.shouldDisplayRole = data.some((listPerson) => listPerson.role !== "")
    }

    // Calculate extra lines needed in order to display each field that should
    // be wrapped.
    const calculateExtraLines = function () {
      return _.each(wrappedFields(), (field) => {
        const allValues = getAggregate(field)
        calculateExtraLineCounts(allValues, field)
      })
    }

    // This keeps track of total line lengths needed for wrapped fields if the
    // values require more than 1 line.
    let extraLinesMap = {}

    // Create a temporary group node to calculate the number of lines needed to
    // display each value.
    const calculateExtraLineCounts = function (values, fieldName) {
      let max = 1
      for (let j = 0, len = values.length; j < len; j += 1) {
        const value = values[j]
        let textWidth = maxWidth
        if (options.showLabels && fieldName === "chart_section") {
          textWidth = maxWidth / 2
        }
        const lineCount = calculateLineCount(value, "text", textWidth)
        if (lineCount > max) {
          max = lineCount
          extraLinesMap[fieldName] = max - 1
        }
      }
      positioningHelper.extraLinesMap = extraLinesMap
    }

    const calculateLineCount = function (value, style = "text", width = maxWidth) {
      const group = svgGroup.append("g").attr("class", "node")
      let lineCount = 1
      group
        .append("text")
        .text(value)
        .each(function () {
          const text = renderTextField(this, value, "display-field", "#333")
          text.call(wrap, width, style)
          const wrappedLines = text.selectAll("tspan")[0]
          return (lineCount = wrappedLines.length)
        })
      group.remove()
      return lineCount
    }

    d3.select(document).on("click", () => {
      if (d3.event.defaultPrevented || $(d3.event.target).hasClass("disabled")) {
        return
      }
      $container
        .find(".node-action-button-group")
        .filter(function () {
          return this.parentNode !== d3.event.target.parentNode
        })
        .remove()
      return removeOptions()
    })

    const coordinates = function (point) {
      const scale = zoomListener.scale()
      const translate = zoomListener.translate()
      return [(point[0] - translate[0]) / scale, (point[1] - translate[1]) / scale]
    }

    const point = function (coordinates) {
      const scale = zoomListener.scale()
      const translate = zoomListener.translate()
      return [coordinates[0] * scale + translate[0], coordinates[1] * scale + translate[1]]
    }

    chart.reloadData = function (centerRoot = true) {
      if (!options.endpoint) {
        error("Endpoint option is required for loading json data")
      }
      return $.getJSON(options.endpoint)
        .done((data) => replaceRoot(data, centerRoot))
        .fail(() => error(`Could not load json from: ${options.endpoint}`))
    }

    chart.loadData = function () {
      if (!options.endpoint) {
        error("Endpoint option is required for loading json data")
      }
      return $.getJSON(options.endpoint)
        .done((data) => initializeTree(data))
        .fail(() => error(`Could not load json from: ${options.endpoint}`))
    }

    chart.loadFromJSON = function () {
      if (!options.jsonData) {
        error("jsonData option is required for loading data")
      }
      return setTimeout(() => initializeTree(options.jsonData), 0)
    }

    chart.zoom = function (scale) {
      baseSvg.call(zoomListener.event)
      zoomListener.center([viewerWidth / 2, viewerHeight / 2])
      const center0 = zoomListener.center()
      const translate0 = zoomListener.translate()
      const coordinates0 = coordinates(center0)
      scale = d3.max([options.minimumZoom, d3.min([options.maximumZoom, scale])])
      const center1 = point(coordinates0)
      moveChart(
        [translate0[0] + center0[0] - center1[0], translate0[1] + center0[1] - center1[1]],
        scale,
        true,
      )

      return chart.trigger("zoom")
    }

    chart.getMaxZoom = function () {
      return options.maximumZoom
    }
    chart.getMinZoom = function () {
      return options.minimumZoom
    }
    chart.getZoom = () => zoomListener.scale()
    chart.getCurrentPosition = () =>
      zoomListener.translate().map((coordinate) => parseFloat(coordinate.toFixed(1)))
    chart.find = (id) => {
      let found = null
      const search = (node) => {
        if (node.id === id || (id === null && typeof node.id === "undefined")) {
          found = node
          return
        }
        if (node.assistants) {
          node.assistants.forEach(search)
        }
        if (node._children) {
          node._children.forEach(search)
        }
        if (node.children) {
          return node.children.forEach(search)
        }
      }

      if (root) {
        search(root)
      } else {
        dataArr.forEach(search)
      }

      if (found) {
        return makeOrgChartNode(found)
      }
    }

    chart.findByPersonId = (id) => {
      let found = null
      const search = (node) => {
        if (node.person_id === id) {
          found = node
          return
        }
        if (node.assistants) {
          node.assistants.forEach(search)
        }
        if (node._children) {
          node._children.forEach(search)
        }
        if (node.children) {
          return node.children.forEach(search)
        }
      }

      if (root) {
        search(root)
      } else {
        dataArr.forEach(search)
      }

      if (found) {
        return makeOrgChartNode(found)
      }
    }

    chart.findAllByPersonId = (id) => {
      const found = []
      const search = (node) => {
        if (node.person_id === id) {
          found.push(node)
        }
        if (node.assistants) {
          node.assistants.forEach(search)
        }
        if (node._children) {
          node._children.forEach(search)
        }
        if (node.children) {
          return node.children.forEach(search)
        }
      }

      if (root) {
        search(root)
      } else {
        dataArr.forEach(search)
      }

      return found
    }

    chart.isDescendantOfNode = (descendant, node) => isDescendantOfNode(descendant, node)

    chart.collapseRecursively = (node) => {
      collapseRecursively(node)
      return update(node, false)
    }
    chart.expandRecursively = function (node) {
      expandRecursively(node)
      return update(node, false)
    }
    chart.getCollapsedNodeIds = () =>
      baseSvg
        .selectAll("g.node")
        .filter((node) => node.klass === "Position" && node._children && node._children.length > 0)
        .data()
        .map((node) => node.id)

    chart.collapseNode = (node) => {
      collapse(node)
      return update(node, false)
    }

    chart.collapseAllByIds = (ids) => {
      ids.forEach((id) => {
        const node = chart.find(parseInt(id, 10))
        if (node) {
          chart.collapseNode(node.attributes)
        }
      })
    }

    chart.focus = function (id) {
      removeOptions()
      return focus(id)
    }
    chart.context = function () {
      return context
    }
    chart.drag = (boundingBox) => {
      const node = dragHelper.getTargetNodeByBoundingBox(boundingBox)
      if (!node) {
        return
      }
      dragHelper.clearDropzones()
      return node.classed("dropzone", true)
    }
    chart.dragging = (boundingBox) => {
      const node = dragHelper.getTargetNodeByBoundingBox(boundingBox)
      dragHelper.clearDropzones()
      return node.classed("dropzone", true)
    }
    chart.drop = (boundingBox) => {
      dragHelper.clearDropzones()
      return dragHelper.getTargetNodeByBoundingBox(boundingBox)
    }
    chart.expandNode = (node) => {
      expandAncestry(node)
      return expand(node)
    }
    chart.center = (node, forceVerticalCenter = false) =>
      centerNode(node.attributes, forceVerticalCenter)

    chart.trueBoundingBox = () => {
      const nodes = d3.merge(context.selectAll("g.node"))
      const xValues = []
      const yValues = []
      nodes.forEach((node) => {
        const transform = window.getComputedStyle(node).transform
        const matrix = new window.WebKitCSSMatrix(transform)
        xValues.push(matrix.m41)
        yValues.push(matrix.m42)
      })
      const minX = Math.min(...xValues)
      const maxX = Math.max(...xValues)
      const width = maxX - minX + nodeWidth

      const minY = Math.min(...yValues)
      const maxY = Math.max(...yValues)
      const height = maxY - minY + positioningHelper.getNodeHeight()

      return { width, height, minX, maxX, minY, maxY }
    }

    // For exports where the SVG and translate > g are both the same size, this
    // will ensure that the chart fits within the svg boundaries.
    chart.fitToSvg = (boundingBox, marginSize) => {
      const svg = $(context.node()).find("> svg")
      const width = Math.max(svg.width(), boundingBox.width)
      const translateGroupWidth = boundingBox.width

      let y = -boundingBox.minY
      let x = width - boundingBox.maxX - nodeWidth / 2 - marginSize * 2

      // Chain of command nodes are negatively positioned within the root node,
      // so we need to adjust the overall positioning if there are chain of
      // command nodes on the chart.
      const chainOfCommand = d3.select("g.node.root").selectAll("g.chain-of-command")
      if (chainOfCommand.size() > 0) {
        const chainOfCommandNodes = d3.merge(chainOfCommand)
        const minY = Math.min(...chainOfCommandNodes.map((n) => d3.select(n).datum().y)) || 0

        y += Math.abs(minY)
      }

      const defaultPageWidth = 1315
      if (
        translateGroupWidth &&
        translateGroupWidth < defaultPageWidth &&
        width > translateGroupWidth
      ) {
        // Subtract half of width difference if translate group is smaller
        const difference = width - translateGroupWidth
        x -= difference / 2
      }

      chart.setPosition(x, y)
    }

    // Move chart relative to current position
    chart.move = (x, y, skipAnimation = false) => {
      x = zoomListener.translate()[0] + x
      y = zoomListener.translate()[1] + y
      chart.setPosition(x, y, null, skipAnimation)
    }
    // Set an absolute chart position
    chart.setPosition = (x, y, scale = null, skipAnimation = false) => {
      scale = scale || zoomListener.scale()
      context
        .select("g")
        .transition()
        .duration(skipAnimation ? 0 : duration)
        .attr("transform", function () {
          if ($(this).parent("svg").data("ignore-chart-resize")) return

          return `translate(${x},${y})scale(${scale})`
        })
      moveChart([x, y], scale).call(transitionsEnded, () => {
        chart.trigger("position-set")
      })
      return this
    }

    chart.renderNode = function (node, options) {
      const { focus, center, withChildren } = options
      updateLink(node)
      if (withChildren) {
        return update(node, center)
      }

      return updateNode(node, focus)
    }

    chart.renderNodeBase = function (element) {
      const node = d3.select(element)
      fullyRenderNodeBase(node)
    }

    chart.removeNode = function (node) {
      return remove(node)
    }
    chart.hideLinksForNode = function (node) {
      return hideLinksForNode(node)
    }
    chart.getRootNode = () => makeOrgChartNode(root)
    chart.replaceRoot = function (data) {
      return replaceRoot(data)
    }

    // Collapses the chart to the specified depth. If no depth is provided, it
    // will collapse to the depth set in the person org_chart settings.
    chart.collapseChartToDepth = (depth) => {
      const rootIsCompany = root.klass === "Company"
      // There are 2 things that this depth offset accounts for:
      // 1. The collapse depth setting starts at 1 while the node depths start at 0.
      //    Because of this, we need to subtract 1 from the collapse depth.
      // 2. When the root node is the company, when want to add 1 to the collapse
      //    depth b/c we don't want to consider the company as part of the depth.
      const depthOffset = rootIsCompany ? 0 : -1

      // Fallback depth is 2 which is the default collapse depth. This is necessary
      // for the external orgchart where settings don't apply.
      const collapseDepth = parseInt(depth || options.collapseDepth || 2, 10) + depthOffset

      collapseRecursivelyToDepth(root, collapseDepth)
      update(root, false)
    }

    chart.getVisibleNodes = () => dataLoader.visibleNodes()
    chart.visiblePositionsJSON = () => {
      const visibleNodes = baseSvg.selectAll("g.node").data()
      const positionIds = visibleNodes
        .map((n) => {
          if (options.displayMode === "cards") {
            return n.person_id
          }
          return n.id
        })
        .filter(Number)
      return JSON.stringify({
        root_node_klass: makeOrgChartNode(visibleNodes[0]).attributes.klass,
        position_ids: positionIds,
      })
    }
    chart.optionIsEqual = (key, value) => _.isEqual(options[key], value)
    chart.getOptions = () => ({ ...defaultOptions, ...options })
    chart.updateOptions = (newOptions) => {
      options = { ...defaultOptions, ...options, ...newOptions }
      if (options.displayMode === "cards") {
        update(dataArr, false)
      } else {
        update(root, false)
      }
      if (options && options.width) {
        viewerWidth = options.width
      }
      if (options && options.height) {
        viewerHeight = options.height
      }
      baseSvg.attr("width", viewerWidth).attr("height", viewerHeight)
    }
    chart.hideOptions = function () {
      return removeOptions()
    }
    chart.colorCodeAllChartNodes = () =>
      colorCodeChartNodes(context.selectAll("g.node"), colorCoder())

    if (options.activeChart) {
      connectChartToRedux({ chart, withChartContainer: options.activeChart })
    }

    _.extend(chart, window.Backbone.Events)
  }
}

export default OrgChart
/* eslint-enable
  no-use-before-define,
  no-underscore-dangle,
  no-param-reassign,
  no-plusplus,
  max-len,
  no-return-assign,
  no-mixed-operators,
  consistent-return,
  prefer-template,
  func-names
*/
