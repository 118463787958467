class PositionDataLoader
  constructor: (baseSvg, chart, endpoint) ->
    @svg = baseSvg
    @chart = chart
    @endpoint = endpoint

  loadVisible: ->
    ids = []
    personIds = []
    visibleWindow = @calculateVisibleWindow()
    chart = @chart
    @svg.selectAll('g.node').filter((d) =>
      return false if d.loaded == true || d.loading == true

      @visibleInWindow(d, visibleWindow)
    ).each((d) ->
      chart.renderNodeBase(@)
      d.loading = true
      if d.id
        ids.push(d.id)
      else if d.person_id
        personIds.push(d.person_id)
    )
    @batchLoadByIds(ids)
    @batchLoadPersonIds(personIds)

  visibleNodes: ->
    visibleWindow = @calculateVisibleWindow(false)
    @svg.selectAll('g.node').filter((d) =>
      @visibleInWindow(d, visibleWindow)
    )

  visibleInWindow: (d, visibleWindow) ->
    d.x >= visibleWindow.x.min &&
      d.y >= visibleWindow.y.min &&
      d.x <= visibleWindow.x.max &&
      d.y <= visibleWindow.y.max

  calculateVisibleWindow: (preload = true) ->
    svgHeight = parseInt(@svg.node().attributes.height.value)
    svgWidth = parseInt(@svg.node().attributes.width.value)
    transformGroup = @svg.select('g[transform]').node()
    transformAttribute = transformGroup.attributes.transform.value
    translateCoordinates = @parseTranslateCoordinates(transformAttribute)

    unless translateCoordinates
      return

    [translateX, translateY] = translateCoordinates
    scale = @parseScale(transformAttribute)
    preloadPercentage = if preload then .25 else 0

    {
      x: {
        min: -(translateX / scale) - (svgWidth * preloadPercentage / scale)
        max: (svgWidth / scale) - (translateX / scale) + (svgWidth * preloadPercentage / scale)
      },
      y: {
        min: -(translateY / scale) - (svgHeight * preloadPercentage / scale)
        max: (svgHeight / scale) - (translateY / scale) + (svgHeight * preloadPercentage / scale)
      }
    }

  # Extract translate x and y from a translate attribute value.
  # Coerces each to a whole number.
  parseTranslateCoordinates: (testString) ->
    match = testString.match(/translate\(([-\d\.]+)(?:,|\s)([-\d\.]+)\)/)

    return unless match
    _.map([match[1], match[2]], (value) -> parseInt(value))

  parseScale: (testString) ->
    match = testString.match(/scale\((.+)\)/)
    return 1 unless match
    parseFloat(match[1])

  loadAllWithCallback: (callback) ->
    ids = @nonLoadedPositionIds()

    promises = @batchLoadByIds(ids)
    return callback() unless promises
    Promise.all(promises).then(->
      callback()
    )

  batchLoadByIds: (positionIds) ->
    return unless positionIds.length > 0

    groups = []
    batchSize = 50
    i = 0
    total = positionIds.length

    while i < total
      groups.push(positionIds.slice(i, i += batchSize))

    promises = []
    for ids in groups
      promises.push @loadByIds(ids)

    promises

  batchLoadPersonIds: (personIds) ->
    return unless personIds.length > 0

    groups = []
    batchSize = 50
    i = 0
    total = personIds.length

    while i < total
      groups.push(personIds.slice(i, i += batchSize))

    promises = []
    for ids in groups
      promises.push @loadPersonByIds(ids)

    promises

  loadByIds: (positionIds) ->
    new Promise((resolve, reject) =>
      prefix = if (@endpoint.indexOf('?') < 0) then '?' else '&'
      paramName = 'ids[]='
      param = prefix + paramName + positionIds.join('&' + paramName)

      unless @endpoint
        throw new Error(
          'Org Chart - Position endpoint is required for loading json data'
        )
      $.getJSON(@endpoint + param)
        .done((positions) =>
          nodes = _.compact(_.map(positions, (position) =>
            orgChartNode = @chart.find position.id
            return unless orgChartNode

            # There is one case where we want to set the children count,
            # and that's for nodes that do not have a children count number
            # set. In 3-level mode, this can happen for reports of a 3rd level
            # node after their 3rd level parent is dragged from the 3rd level
            # to a higher level. Its children are currently being loaded in
            # without any children counts.
            unless isNaN(orgChartNode.get('children_count'))
              position = _.omit(position, 'children_count')

            orgChartNode.set(Object.assign({}, position, loaded: true, loading: false))
            orgChartNode
          ))

          @chart.trigger('positions-loaded')
          resolve()
        ).fail(=>
          reject()
          throw new Error("Org Chart - Could not load json from: #{@endpoint}")
        )
    )

  loadPersonByIds: (personIds) ->
    new Promise((resolve, reject) =>
      paramName = 'ids[]='
      param = '?' + paramName + personIds.join('&' + paramName)

      $.getJSON('/api/app/v1/people/organize' + param)
        .done((people) =>
          nodes = _.map(people, (person) =>
            orgChartNode = @chart.findByPersonId person.id
            return unless orgChartNode

            orgChartNode.set(_.omit(person, ['id']))
            orgChartNode.set({loaded: true, loading: false})
            orgChartNode
          )

          nodes.forEach(@chart.renderNode,  { withChildren: false })
          @chart.trigger('positions-loaded')
          resolve()
        ).fail(=>
          reject()
          throw new Error("Org Chart - Could not load json from: #{@endpoint}")
        )
    )

  nonLoadedPositionIds: ->
    allNodes = @svg.selectAll('g.node').data()
    nonLoaded = _.reject(allNodes, (node) ->
      node.loaded == true || node.loading == true
    )
    nonLoaded.map((d) -> d.id).filter(Number)

export default PositionDataLoader
