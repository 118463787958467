/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import { filter } from "lodash"
import { useTranslation } from "react-i18next"

import {
  AllowedAttribute,
  AllowedAttributeInputTypeEnum,
  Collections,
  HeadcountPlanChangeProjection,
  Option,
} from "types/graphql.d"

import {
  dropdownCellKeyToCollectionMap,
  formatBasePay,
  formatVariablePay,
  isDropdownCellKey,
  isVariablePayKey,
  isVariablePayTypeKey,
} from "../components/headcountPlanning/HeadcountPlanDatasheet/helpers"
import { HeadcountPlanDatasheetRow } from "../components/headcountPlanning/HeadcountPlanDatasheet/types"
import { Column, FieldType } from "../components/headcountPlanning/TableDatasheet/types"

export function useHeadcountPlanDatasheet({
  attributes,
  positions,
  dropdownCellCollections,
  hideOptions,
  showStatus,
  newPositionIdPrefix,
}: {
  attributes: AllowedAttribute[]
  positions: HeadcountPlanChangeProjection[]
  dropdownCellCollections: Partial<Collections>
  hideOptions: boolean
  showStatus: boolean
  newPositionIdPrefix?: string
}) {
  const selectedAttributes = filter(attributes, (row) => row.isSelected)
  const showSubmittedBy = positions.some((position) => position.submittedBy !== null)
  return {
    rows: getRows(positions),
    columns: useColumns(
      selectedAttributes,
      dropdownCellCollections,
      hideOptions,
      showStatus,
      showSubmittedBy,
      newPositionIdPrefix,
    ),
  }
}

const getRows = (projections: HeadcountPlanChangeProjection[]) =>
  projections
    .filter(
      (projection) =>
        projection.type !== "new" ||
        projection.action !== "destroy_existing" ||
        projection.excludedForParticipant,
    )
    .map(hcpProjectionToRow)

const hcpProjectionToRow = (
  projection: HeadcountPlanChangeProjection,
): HeadcountPlanDatasheetRow => ({
  id: projection.id,
  action: projection.action,
  deletable: projection.deletable,
  excluded: projection.excludedForParticipant,
  filled: projection.filled,
  parentPosition: projection.parentPosition,
  participantId: projection.participantId,
  payload: projection.payload,
  position: projection.position,
  positionAttributes: projection.positionAttributes,
  positionAttributesWithEdits: projection.positionAttributesWithEdits,
  revisionNumber: projection.revisionNumber,
  rootEventId: projection.rootEventId,
  status: projection.status,
  submittedBy: projection.submittedBy?.person?.name,
  systemId: projection.systemIdentifier,
  type: projection.type,
})

const useColumns = (
  attributes: AllowedAttribute[],
  dropdownCellCollections: Partial<Collections>,
  hideOptions: boolean,
  showStatus: boolean,
  showSubmittedBy: boolean,
  newPositionIdPrefix?: string,
): Column<HeadcountPlanDatasheetRow>[] => {
  const { t } = useTranslation()
  const defaultColumns: Column<HeadcountPlanDatasheetRow>[] = [
    {
      id: "status",
      label: "Status",
      hidden: !showStatus,
      width: "medium",
    },
    {
      id: "options",
      label: "",
      enableSorting: false,
      enableFiltering: false,
      enableGrouping: false,
      hidden: hideOptions,
      width: "small",
    },
    {
      id: "position_id",
      label: "Position ID",
      width: "medium",
      enableSorting: false,
      enableGrouping: false,
      enableFiltering: false,
      info: t("v2.headcount_plan.datasheet.position_id_info", { prefix: newPositionIdPrefix }),
    },
    {
      id: "type",
      label: "Type",
      accessorFn: (row) => {
        if (row.type !== "new" && row.payload.end_date)
          return "position_type_eliminated".t("headcount_plan")

        return `position_type_${row.type}`.t("headcount_plan")
      },
      width: "medium",
    },
    {
      id: "submittedBy",
      label: "column_submitted_by".t("headcount_plan"),
      hidden: !showSubmittedBy,
      width: "medium",
    },
  ]

  return defaultColumns.concat(
    attributes.map((attribute) => {
      let options: Option[] | null = null
      if (isDropdownCellKey(attribute.id)) {
        const collection = dropdownCellCollections[dropdownCellKeyToCollectionMap[attribute.id]]
        if (collection) {
          options = collection.options.nodes
        }
      }
      if (isVariablePayTypeKey(attribute.id)) {
        options = dropdownCellCollections.variablePayPayTypes?.options.nodes ?? []
      }
      return {
        id: attribute.id,
        label: attribute.name,
        enableFiltering: attribute.id !== "justification",
        enableSorting: attribute.id !== "justification",
        enableGrouping: attribute.id !== "justification",
        options,
        width: attribute.id === "justification" ? "large" : "medium",
        accessorFn: (row) => {
          if (options !== null) {
            return options.find(
              (option) => option.id === row.positionAttributesWithEdits[attribute.id],
            )?.label
          }

          if (attribute.id === "filled") {
            return `position_${row.filled ? "filled" : "open"}`.t("headcount_plan")
          }

          if (isVariablePayKey(attribute.id)) {
            return formatVariablePay(row.positionAttributesWithEdits, attribute.id)
          }

          if (attribute.id === "budgeted_base_pay_rate") {
            return formatBasePay(row.positionAttributesWithEdits)
          }

          const value =
            typeof row.positionAttributesWithEdits[attribute.id] === "string"
              ? row.positionAttributesWithEdits[attribute.id]
              : row.positionAttributesWithEdits[attribute.id]?.label

          return value ?? "None"
        },
        editableFn: (row) => {
          if (attribute.id === "start_date" && row.type !== "new") {
            return false
          }
          if (attribute.id === "planned_termination_date" && (row.type === "new" || !row.filled)) {
            return false
          }
          if (attribute.id === "projected_hiring_date" && row.type === "new") {
            return false
          }
          if (["filled", "filled_by_name", "filled_by_id"].includes(attribute.id)) {
            return false
          }
          if (
            attribute.id === "position_hours_per_week" &&
            row.positionAttributesWithEdits.budgeted_base_pay_type !==
              "position_base_pay_type_hourly"
          ) {
            return false
          }

          return true
        },
        type: attribute.type,
        fieldType: mapInputToFieldType(attribute.inputType),
      }
    }),
  )
}

function mapInputToFieldType(
  inputType: AllowedAttributeInputTypeEnum | undefined | null,
): FieldType {
  switch (inputType) {
    case AllowedAttributeInputTypeEnum.ForcedAutocomplete:
      return FieldType.ForcedAutocomplete
    case AllowedAttributeInputTypeEnum.SuggestedAutocomplete:
      return FieldType.SuggestedAutocomplete
    case AllowedAttributeInputTypeEnum.StandardEditable:
      return FieldType.Standard
    case AllowedAttributeInputTypeEnum.Dropdown:
      return FieldType.SelectDropdown
    case AllowedAttributeInputTypeEnum.Datepicker:
      return FieldType.DatePicker
    default:
      return FieldType.NonEditable
  }
}
