import { FloatingPortal } from "@floating-ui/react"
import { AnimatePresence, motion } from "framer-motion"
import React, { ReactNode, RefObject, useEffect, useState } from "react"

import { IS_V2 } from "./utils/constants"

interface StickyHeaderProps {
  containerRef: RefObject<HTMLDivElement>
  elementRef: RefObject<HTMLDivElement>
  children: ReactNode
}

function StickyHeader({ containerRef, elementRef, children }: StickyHeaderProps) {
  const [showStickyHeader, setShowStickyHeader] = useState(false)

  useEffect(() => {
    const container = containerRef.current
    const handleScroll = () => {
      const element = elementRef.current
      if (
        element &&
        container &&
        element.getBoundingClientRect().height > 0 &&
        container.scrollTop >= element.getBoundingClientRect().height
      ) {
        setShowStickyHeader(true)
      } else {
        setShowStickyHeader(false)
      }
    }

    container?.addEventListener("scroll", handleScroll)

    return () => {
      container?.removeEventListener("scroll", handleScroll)
    }
  }, [containerRef, elementRef])

  const cssId = IS_V2
    ? "react-profile-panel__sticky-header-v2"
    : "react-profile-panel__sticky-header"

  return showStickyHeader ? (
    <FloatingPortal id={cssId} root={containerRef.current}>
      <AnimatePresence>
        <motion.div
          className="content border--elevation"
          key="stickyHeader"
          initial="hide"
          animate="show"
          exit="hide"
          variants={showAndHideIconVariants}
        >
          {children}
        </motion.div>
      </AnimatePresence>
    </FloatingPortal>
  ) : null
}

export { StickyHeader }

const showAndHideIconVariants = {
  show: {
    opacity: 1,
    y: "0px",
    transition: {
      type: "spring",
      stiffness: 300,
      damping: 30,
    },
  },
  hide: {
    opacity: 0,
    y: "-65px",
    transition: {
      type: "spring",
      stiffness: 300,
      damping: 30,
    },
  },
}
