/* eslint-disable react/jsx-props-no-spreading */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { getLocalTimeZone, today } from "@internationalized/date"
import cn from "classnames"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { AriaDateRangePickerProps, useDateRangePicker } from "react-aria"
import { Label as AriaLabel, type DateRange, type DateValue } from "react-aria-components"
import { useTranslation } from "react-i18next"
import { useDateRangePickerState } from "react-stately"
import { useOnClickOutside } from "usehooks-ts"

import { Button } from "v2/react/shared/forms/DateRange/Button"
import { DateField, type DateFieldProps } from "v2/react/shared/forms/DateRange/DateField"
import {
  buildDateRangeOptions,
  type DateRangeOption,
  type PresetDateRangeKey,
} from "v2/react/shared/forms/DateRange/dates"
import { Dialog } from "v2/react/shared/forms/DateRange/Dialog"
import QuickSelectMenu from "v2/react/shared/forms/DateRange/QuickSelectMenu"
import { RangeCalendar } from "v2/react/shared/forms/DateRange/RangeCalendar"
import { InputErrorText } from "v2/react/shared/forms/InputErrorText"

interface Props {
  className?: string
  label?: string
  errorMessage?: string
  excludeQuickSelectFutureOptions?: boolean
  timeZone?: string
  onDateRangeSelection?: (dateRange?: DateRange | null) => void
}

type DateRangeProps<T extends DateValue> = AriaDateRangePickerProps<T> & Props

function DateRangeInner<T extends DateValue>({
  className,
  label,
  errorMessage,
  maxValue,
  minValue,
  excludeQuickSelectFutureOptions,
  timeZone,
  onDateRangeSelection,
  ...props
}: DateRangeProps<T>) {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const [clickedSegmentRef, setClickedSegmentRef] = useState<
    React.RefObject<HTMLDivElement> | undefined
  >(undefined)
  const [selectedRange, setSelectedRange] = useState("last_12_months")
  const [dialogContent, setDialogContent] = useState("quick_select")
  const [calendarFocusDate, setCalendarFocusDate] = useState<DateValue | undefined>(
    props.value?.start,
  )
  const state = useDateRangePickerState(props)
  const groupRef = useRef<HTMLDivElement>(null)
  const dateRangeRef = useRef<HTMLDivElement>(null)
  const startFieldRef = useRef<HTMLDivElement>(null)
  const endFieldRef = useRef<HTMLDivElement>(null)
  const {
    buttonProps,
    calendarProps,
    dialogProps,
    endFieldProps,
    groupProps,
    labelProps,
    startFieldProps,
  } = useDateRangePicker({ "aria-labelledby": "Date Range Selector", ...props }, state, groupRef)

  const dialogIsCalendar = dialogContent === "calendar"
  const dialogIsQuickSelect = dialogContent === "quick_select"
  const calendarIsOpen = isOpen && dialogIsCalendar

  // Ensure that we get the current date in the specified timezone.
  const currentDate = useMemo(
    () => (timeZone ? today(timeZone) : today(getLocalTimeZone())),
    [timeZone],
  )
  const dateRanges = useMemo(() => buildDateRangeOptions(currentDate), [currentDate])

  const determineSelectedRange = useCallback(
    (value: DateRange | null | undefined) => {
      if (!value || !value.start || !value.end) {
        setSelectedRange("")
      } else {
        const matchedRange = Object.keys(dateRanges).find((date) => {
          const rangeKey = date as DateRangeOption
          if (
            dateRanges[rangeKey].start.format("YYYY-MM-DD") === value?.start.toString() &&
            dateRanges[rangeKey].end.format("YYYY-MM-DD") === value?.end.toString()
          ) {
            return dateRanges[rangeKey]
          }
          return null
        })

        setSelectedRange(matchedRange || "custom_date_range")
      }
    },
    [setSelectedRange, dateRanges],
  )

  useEffect(() => {
    determineSelectedRange(props.value)
  }, [props.value, determineSelectedRange])

  useEffect(() => {
    determineSelectedRange(state.value)
  }, [state.value, determineSelectedRange])

  const handleClickOutside = (e: MouseEvent | TouchEvent) => {
    const eventOutsidePickerOrDialog = (e: MouseEvent | TouchEvent) =>
      !(e?.target as HTMLElement).matches(
        ".Date-Range, .Date-Range *, .Date-Range-Dialog, .Date-Range-Dialog *",
      )

    if (eventOutsidePickerOrDialog(e)) {
      setDialogContent("quick_select")
      setIsOpen(false)
      onDateRangeSelection?.(state.value)
    }
  }

  useOnClickOutside(dateRangeRef, handleClickOutside)

  const handleDialog = () => {
    if (dialogIsCalendar) {
      setDialogContent("quick_select")
      setIsOpen(true)
    } else {
      setIsOpen(!isOpen)
    }
  }

  const handleSegmentFocus: DateFieldProps["onSegmentFocus"] = (_e, date, ref) => {
    if (!calendarIsOpen) {
      setDialogContent("calendar")
      setClickedSegmentRef(ref)
      setCalendarFocusDate(date || currentDate)
      setIsOpen(true)
    }
  }

  const handleDateFieldChange: DateFieldProps["onDateFieldChange"] = useCallback(
    (date) => setCalendarFocusDate(date || currentDate),
    [currentDate],
  )

  const handlePresetRangeSelect = (
    rangeOptionKey: PresetDateRangeKey,
    dateValue?: DateRange | null,
  ) => {
    setSelectedRange(rangeOptionKey)
    onDateRangeSelection?.(dateValue)
  }

  const handleDateRangePickerSelect = (dateRange: DateRange | null) => {
    if (!dateRange) return
    setIsOpen(false)
    state.setDateRange(dateRange)
    onDateRangeSelection?.(dateRange)
  }

  const handleDateFieldSelection: DateFieldProps["onDateFieldSelection"] = (e) => {
    const target = e.target
    if (!(target instanceof HTMLElement)) return

    // Hitting enter on the end field should close the dialog and trigger the
    // callback.
    if (endFieldRef.current?.contains(target)) {
      target.blur()
      setIsOpen(false)
      onDateRangeSelection?.(state.value)
    }

    // Hitting enter on the start field should transfer focus to the end field.
    if (startFieldRef.current?.contains(target)) {
      target.blur()
      const firstChild = endFieldRef.current?.firstElementChild
      if (firstChild instanceof HTMLElement) {
        firstChild.focus()
      }
    }
  }

  return (
    <div className={cn("Date-Range relative", className)} ref={dateRangeRef}>
      <div className="mb-1 items-center gap-x-2 flex">
        <AriaLabel
          id="Date Range Selector"
          className="!mr-0 font-bold text-neutral-100"
          {...labelProps}
        >
          {label}
          {selectedRange.length ? ": " : null}
        </AriaLabel>
        <span className="text-neutral-64">
          {selectedRange.length ? t(`v2.date.ranges.${selectedRange}`) : null}
        </span>
      </div>
      <div
        {...groupProps}
        ref={groupRef}
        className={cn(
          "w-full overflow-hidden rounded-lg bg-white flex",
          errorMessage ? "border--error" : "border--main",
        )}
      >
        <DateField
          {...startFieldProps}
          fieldRef={startFieldRef}
          onDateFieldChange={handleDateFieldChange}
          onSegmentFocus={handleSegmentFocus}
          onDateFieldSelection={handleDateFieldSelection}
          minValue={minValue}
        />
        <DateField
          {...endFieldProps}
          fieldRef={endFieldRef}
          onDateFieldChange={handleDateFieldChange}
          onSegmentFocus={handleSegmentFocus}
          onDateFieldSelection={handleDateFieldSelection}
          maxValue={maxValue}
        />
        <Button
          className={cn(
            "menu-trigger px-3 ease-in-out",
            isOpen && dialogIsQuickSelect ? "bg-neutral-3" : "bg-transparent",
          )}
          onClick={handleDialog}
          {...buttonProps}
        >
          <FontAwesomeIcon icon={["fas", "caret-down"]} />
        </Button>
      </div>
      {errorMessage && <InputErrorText message={errorMessage} />}
      {isOpen && (
        <Dialog
          {...dialogProps}
          focusRefOnMount={dialogIsCalendar ? clickedSegmentRef : undefined}
          className={cn("z-10", { "box-border w-[280px]": dialogIsCalendar })}
        >
          {dialogIsQuickSelect && (
            <QuickSelectMenu
              selectedRange={selectedRange}
              setSelectedRange={handlePresetRangeSelect}
              setActiveRange={state.setValue}
              setDialogContent={setDialogContent}
              setIsOpen={setIsOpen}
              excludeFutureOptions={excludeQuickSelectFutureOptions}
              dateRanges={dateRanges}
            />
          )}
          {dialogIsCalendar && (
            <RangeCalendar
              {...calendarProps}
              autoFocus={false}
              minValue={minValue}
              maxValue={maxValue}
              currentDate={currentDate}
              focusedValue={calendarFocusDate}
              onFocusChange={setCalendarFocusDate}
              onChange={handleDateRangePickerSelect}
            />
          )}
        </Dialog>
      )}
    </div>
  )
}

function DateRange<T extends DateValue>({
  label,
  description,
  errorMessage,
  maxValue,
  minValue,
  timeZone,
  excludeQuickSelectFutureOptions,
  onDateRangeSelection,
  ...props
}: DateRangeProps<T>) {
  return (
    <DateRangeInner
      label={label}
      description={description}
      errorMessage={errorMessage}
      maxValue={maxValue}
      minValue={minValue}
      excludeQuickSelectFutureOptions={excludeQuickSelectFutureOptions}
      timeZone={timeZone}
      onDateRangeSelection={onDateRangeSelection}
      {...props}
    />
  )
}

export { DateRange }
