import React from "react"
import { createRoot } from "react-dom/client"

import { Overview } from "v2/react/components/headcountPlanning/Overview"
import { StoreProvider } from "v2/redux/StoreProvider"

$.onmount("#react-headcount-plans-overview-container", () => {
  const container = document.getElementById("react-headcount-plans-overview-container")
  const root = createRoot(container)
  const { headcountPlanId, maxChartDepth, rootPositionId, participantIds } = container.dataset

  root.render(
    <StoreProvider>
      <Overview
        headcountPlanId={headcountPlanId}
        maxChartDepth={maxChartDepth}
        rootPositionId={rootPositionId}
        participantIds={JSON.parse(participantIds)}
      />
    </StoreProvider>,
  )
})
