import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { skipToken } from "@reduxjs/toolkit/dist/query"
import classNames from "classnames"
import React, { ReactElement, useEffect } from "react"

import { Person } from "types/graphql"
import { InputErrorText } from "v2/react/shared/jsonForms/JsonFormControls/InputErrorText"
import { Pulser } from "v2/react/shared/loaders/Pulser"
import { useGetPositionForRequisitionQuery } from "v2/redux/GraphqlApi/JobRequisitionsApi"

interface Props {
  enabled: boolean
  errorMessage: string
  handleClearValue: () => void
  handlePositionLabelClick: () => void
  inputLabel: string
  positionId: string
  positionLabel: string
  showError: boolean
}

function PositionInputDisplay({
  enabled,
  errorMessage,
  handleClearValue,
  handlePositionLabelClick,
  inputLabel,
  positionId,
  positionLabel,
  showError,
}: Props) {
  const {
    data: positionData,
    isLoading,
    isUninitialized,
  } = useGetPositionForRequisitionQuery(positionId ? { positionId } : skipToken)

  const title: string = isLoading ? positionLabel : positionData?.position?.jobTitle?.name || ""
  const identifier: string = isLoading ? "" : positionData?.position?.systemIdentifier || ""
  const filledBy: string = isLoading
    ? "..."
    : (positionData?.position?.filledBy || []).map((person: Person) => person.name).join(", ")
  const lastFilledBy: string = isLoading ? "..." : positionData?.position?.lastFilledBy?.name || ""

  useEffect(() => {
    if (!isUninitialized && !isLoading && !positionData?.position) handleClearValue()
  }, [isUninitialized, isLoading, positionData, handleClearValue])

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handlePositionLabelClick()
    }
  }

  return (
    <div className={classNames("input-group", { "form-error": showError })}>
      <label>{inputLabel}</label>
      <div
        className={classNames({ relative: enabled && positionLabel.length })}
        onClick={handlePositionLabelClick}
        onKeyDown={handleKeyDown}
        role="button"
        tabIndex={0}
      >
        <div
          className={classNames("input suffix-pad h-auto", {
            "$bg-neutral-3": !enabled,
          })}
        >
          <div>
            <LabelTitle
              identifier={identifier}
              isLoading={isLoading}
              positionId={positionId}
              title={title}
            />
          </div>
          <LabelFilledBy
            filledBy={filledBy}
            isLoading={isLoading}
            lastFilledBy={lastFilledBy}
            positionId={positionId}
          />
        </div>
        {enabled && positionLabel !== "" && (
          <button
            className="suffix skip-tab show-only-with-text"
            onClick={handleClearValue}
            type="button"
          >
            <FontAwesomeIcon icon={["far", "times"]} />
          </button>
        )}
      </div>
      <InputErrorText message={errorMessage} showError={showError} />
    </div>
  )
}

interface LabelTitleProps {
  isLoading: boolean
  positionId: string
  title: string
  identifier: string
}

const LabelTitle = ({
  isLoading,
  positionId,
  title,
  identifier,
}: LabelTitleProps): ReactElement => {
  if (isLoading)
    return (
      <div className="ml-2">
        <Pulser />
      </div>
    )
  if (!positionId) return <div>&nbsp;</div>
  if (title === "" && identifier === "") return <div>&nbsp;</div>
  if (title === "") return <div>{identifier}</div>
  return <div>{`${title}, ${identifier}`}</div>
}

interface LabelFilledByProps {
  filledBy: string
  isLoading: boolean
  lastFilledBy: string
  positionId: string
}

const LabelFilledBy = ({
  filledBy,
  isLoading,
  lastFilledBy,
  positionId,
}: LabelFilledByProps): ReactElement | null => {
  const filledByNames = filledBy !== "" ? filledBy : lastFilledBy
  const label =
    filledBy !== "" ? "filled_by".t("job_requisition") : "last_filled_by".t("job_requisition")
  const value = isLoading ? <>&hellip;</> : filledByNames

  if (!isLoading && positionId === "") return null
  if (!isLoading && filledBy === "" && lastFilledBy === "") return null

  // The placeholder here has vertical content, and we only axe it and the
  // filled by section if the results are empty (an open position with no prior
  // people).
  return (
    <div className="text-sm text-neutral-64">
      <span>
        {label} {value}
      </span>
    </div>
  )
}

export { PositionInputDisplay }
