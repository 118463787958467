import * as d3 from "d3-hierarchy"
import OrgChart from "org_chart/chart/orgChart"
import RelationalNodeDataStore from "org_chart/chart/utils/relationalNodeDataStore"
import React, { useState } from "react"

import { FeatureFlags, HeadcountPlanChangeProjection } from "types/graphql.d"
import { HeadcountPlanningNavTitleWithSubtitle } from "v2/react/components/headcountPlanning/HeadcountPlanningNav"
import { PlanTopbarMenu } from "v2/react/components/headcountPlanning/PlanTopbarMenu"
import { InProgressAlert } from "v2/react/components/headcountPlanning/shared/InProgressAlert"
import { getNavLinks } from "v2/react/components/headcountPlanning/shared/navigation"
import { PlanTypeIndicator } from "v2/react/components/headcountPlanning/ShowParticipant/PlanTypeIndicator"
import {
  SubmitProposalButton,
  SubmitProposalModal,
} from "v2/react/components/headcountPlanning/ShowParticipant/SubmitProposalButton"
import { DisplayState } from "v2/react/components/headcountPlanning/types"
import { OrgChartViewOptions } from "v2/react/components/orgChart/Navigation/OrgChartViewOptions"
import RootProvider from "v2/react/components/RootProvider"
import { Spinner } from "v2/react/shared/loaders/Spinner"
import PageNav from "v2/react/shared/navigation/PageNav"
import { ActionBlock } from "v2/react/shared/navigation/PageNav/ActionBlock"
import { LinkBlockSmall } from "v2/react/shared/navigation/PageNav/LinkBlock"
import { LinkGroup } from "v2/react/shared/navigation/PageNav/LinkGroup"
import { TitleBlockLarge } from "v2/react/shared/navigation/PageNav/TitleBlock"
import { getCookie } from "v2/react/utils/cookies"
import { UrlHelper } from "v2/react/utils/urls"
import { useGetFeatureFlagsQuery } from "v2/redux/GraphqlApi"
import { useGetParticipantOrgChartQuery } from "v2/redux/GraphqlApi/HeadcountPlanningApi"

RelationalNodeDataStore.load({ keys: {}, index: {} })

interface Props {
  headcountPlanId: string
  participantId: string
}

export function ParticipantOrgChartInner({ headcountPlanId, participantId }: Props) {
  const displayStateCookie = getCookie(
    `built__display-state-for-hcp-${headcountPlanId}`,
  ) as DisplayState
  const [displayState, setDisplayState] = useState<DisplayState>(displayStateCookie || "approved")
  const featureFlags = useGetFeatureFlagsQuery()
  const participantPage = useGetParticipantOrgChartQuery({ headcountPlanId, participantId })
  const headcountPlan = participantPage.data?.headcountPlan
  const isFinalized = !!headcountPlan?.lockedAt
  const participant = headcountPlan?.participant
  const hcpPositions =
    isFinalized && displayState === "approved"
      ? participant?.approvedPositionsForOrgchart
      : participant?.allPositionsForOrgchart
  const person = participant?.person
  const position = participant?.position
  const submitsToPerson = participant?.submitsTo?.person
  const hasSubmitted = participant?.status === "complete"
  const [submitProposalModalIsOpen, setSubmitProposalModalIsOpen] = React.useState(false)

  if (
    participantPage.isLoading ||
    !hcpPositions ||
    !headcountPlan ||
    featureFlags.isFetching ||
    !featureFlags.data?.currentCompany?.featureFlags
  )
    return <Spinner />

  const ff: FeatureFlags = featureFlags.data.currentCompany.featureFlags

  // Reset contents of org chart container
  const container = document.getElementById("organize-container")
  if (container) container.innerHTML = ""

  const stratify = d3
    .stratify()
    .id((d): string => (d as HeadcountPlanChangeProjection).id)
    .parentId((d): string => {
      const node = d as HeadcountPlanChangeProjection
      const reportsToId = node.positionAttributesWithEdits?.reports_to?.id
      if (reportsToId) {
        return `position_${reportsToId}`
      }
      return ""
    })

  const positions = [...hcpPositions]
  const topPosition = {
    id: `position_${position?.id}`,
    name: person?.name,
    title: position?.title,
  }
  positions.push(topPosition)
  const chartData = stratify(positions)
  const chartOptions = {
    jsonData: chartData,
    loadAsync: true,
    positionsEndpoint: `/api/app/v1/headcount_plans/${headcountPlanId}/organize?participant_id=${participant?.id}&display_state=${displayState}`,
    orgchartLite: true,
    displayFields: ["avatar", "name", "title"],
    showLabels: true,
    dragAndDropEnabled: false,
  }

  const chart = new OrgChart("#organize-container", chartOptions)
  chart.loadFromJSON()

  return (
    <PageNav>
      <TitleBlockLarge>
        <HeadcountPlanningNavTitleWithSubtitle
          title={`${person?.name}, ${position?.title}`}
          subtitle={headcountPlan.name}
          subtitleLinkTo={UrlHelper.headcountPlanPath(headcountPlanId)}
        />
      </TitleBlockLarge>
      <LinkBlockSmall>
        <LinkGroup
          links={getNavLinks({
            isOwner: false,
            active: "Org Chart",
            headcountPlanId,
            participantId,
            ff,
          })}
        />
      </LinkBlockSmall>
      <ActionBlock>
        <div className="gap-2 flex">
          {isFinalized && <PlanTypeIndicator type={displayState} />}
          {!hasSubmitted && (
            <>
              <SubmitProposalModal
                submitsToPerson={submitsToPerson}
                isOpen={submitProposalModalIsOpen}
                setIsOpen={setSubmitProposalModalIsOpen}
                headcountPlanId={headcountPlanId}
                participantId={participantId}
              />
              <SubmitProposalButton
                disabled={!participant?.canSubmit}
                setIsOpen={setSubmitProposalModalIsOpen}
              />
            </>
          )}
          {isFinalized ? (
            <PlanTopbarMenu
              isFinalized={isFinalized}
              displayState={displayState}
              setDisplayState={setDisplayState}
              headcountPlanId={headcountPlanId}
            />
          ) : null}
        </div>
      </ActionBlock>
      {!participant?.canSubmit && <InProgressAlert />}
      <OrgChartViewOptions chart={chart} />
    </PageNav>
  )
}

const ParticipantOrgChart = ({ headcountPlanId, participantId }: Props) => {
  const getVisualHeight = () => {
    const viewportHeight = window.innerHeight || document.documentElement.clientHeight || 0
    const headerHeight = document.getElementsByClassName("page-nav")[0]?.scrollHeight || 0
    const alertHeight = document.getElementsByClassName("alert-top")[0]?.scrollHeight || 0
    const height = viewportHeight - headerHeight - alertHeight

    if (height < 600) return 600
    return height
  }
  return (
    <RootProvider>
      <ParticipantOrgChartInner headcountPlanId={headcountPlanId} participantId={participantId} />

      <div
        className="organize-container bg-primary-3-solid"
        id="organize-container"
        style={{ height: getVisualHeight() }}
      />
    </RootProvider>
  )
}

export { ParticipantOrgChart }
