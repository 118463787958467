import dayjs from "dayjs"
import React from "react"
import { useTranslation } from "react-i18next"

import { FeatureFlags, IntegrationFlags, ProfilePanelPermissions } from "types/graphql"
import { ProfilePanelMode } from "types/graphql.enums"
import { ModuleListItem } from "v2/react/components/orgChart/ProfilePanel/ModuleListItem"
import { useSelectedBox } from "v2/react/components/orgChart/ProfilePanel/Settings/hooks/useSelectedBox"
import { AccordionModulePanel } from "v2/react/shared/layout/AccordionModulePanel"
import { useAppSelector } from "v2/redux/store"

interface PlanningAndForecastingProps {
  abilities: ProfilePanelPermissions
  featureFlags: FeatureFlags
  integrationFlags: IntegrationFlags
}

function PlanningAndForecasting({
  abilities,
  featureFlags,
  integrationFlags,
}: PlanningAndForecastingProps) {
  const { currentBox, toggleBox } = useSelectedBox("position_planning", ProfilePanelMode.Position)
  const position = useAppSelector((state) => state.profilePanel.position)
  const { t } = useTranslation()

  if (!position || !currentBox) return null
  if (!featureFlags.positionManagement) return null

  const canReadBudgeting = window.gon.can_manage_chart || abilities.canReadBudgeting
  const canReadRestrictedPositionFields = abilities.canReadRestrictedPositionFields

  const employeeType = (
    <ModuleListItem>
      <div>{t("v2.profile_panel.employee_type")}</div>
      <div>{position?.employeeType?.labelTranslated}</div>
    </ModuleListItem>
  )

  const payGrade =
    integrationFlags.supportsPayGrades && canReadRestrictedPositionFields && canReadBudgeting ? (
      <ModuleListItem>
        <div>{t("v2.profile_panel.pay_grade")}</div>
        <div>{position.positionType?.payGrade?.codeNameLabel}</div>
      </ModuleListItem>
    ) : null

  const positionStatus = (
    <ModuleListItem>
      <div>{t("v2.profile_panel.position_status")}</div>
      <div>{position?.positionStatus?.label}</div>
    </ModuleListItem>
  )

  const projectedHireDate = (
    <ModuleListItem>
      <div>{t("v2.profile_panel.projected_hire_date")}</div>
      <div>
        {position?.projectedHireDate && dayjs(position?.projectedHireDate).format("MMM D, YYYY")}
      </div>
    </ModuleListItem>
  )

  const hiringPriority = (
    <ModuleListItem>
      <div>{t("v2.profile_panel.hiring_priority")}</div>
      <div>{position.hiringPriority ? t(`v2.defaults.${position.hiringPriority}`) : ""}</div>
    </ModuleListItem>
  )

  const budgetedBasePay = (
    <ModuleListItem>
      <div>{t("v2.profile_panel.budgeted_base_pay")}</div>
      <div>
        {position?.positionBasePay &&
          window.App.Helpers.formatCurrency(position.positionBasePay, { trailing: false })}
      </div>
    </ModuleListItem>
  )

  const variablePays = (position.variablePays || []).map((payType) =>
    payType.display_name ? (
      <ModuleListItem key={payType.display_name}>
        <div>{payType.display_name}</div>
        <div>{payType.amount ? payType.formatted_value : null}</div>
      </ModuleListItem>
    ) : null,
  )

  const totalAnnualBudget = (
    <ModuleListItem>
      <div>{t("v2.profile_panel.total_annual_budget")}</div>
      <div>
        {position?.positionPayAnnualized &&
          window.App.Helpers.formatCurrency(position.positionPayAnnualized, { trailing: false })}
      </div>
    </ModuleListItem>
  )

  const numDaysOpen = (
    <ModuleListItem>
      <div>{t("v2.profile_panel.field_open_for")}</div>
      <div>{position?.openSince && dayjs(Date.now()).diff(position.openSince, "days")}</div>
    </ModuleListItem>
  )

  const fundingSources = (
    <ModuleListItem>
      <div>{t("v2.profile_panel.funding_source")}</div>
      <div className="max-width-half">
        {(position?.fundingSources || []).map((source) => source.name).join(", ")}
      </div>
    </ModuleListItem>
  )

  const headerContent = () => <div className="title">{t("v2.profile_panel.position_planning")}</div>

  return (
    <AccordionModulePanel
      headerContent={headerContent()}
      additionalToggleAction={toggleBox}
      collapsed={currentBox.collapsed}
      title={currentBox.name}
    >
      {employeeType}
      {positionStatus}
      {projectedHireDate}
      {hiringPriority}
      {payGrade}
      {abilities.canReadBudgeting && budgetedBasePay}
      {abilities.canReadBudgeting && variablePays}
      {abilities.canReadBudgeting && totalAnnualBudget}
      {numDaysOpen}
      {abilities.canReadBudgeting && fundingSources}
    </AccordionModulePanel>
  )
}

export { PlanningAndForecasting }
