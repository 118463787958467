import cn from "classnames"
import type { TFunction } from "i18next"
import { isEmpty, isEqual, omitBy } from "lodash"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import type { Collection } from "types/graphql"
import { getPlaceholderLabel } from "v2/react/components/dashboard/helpers/filters"
import { FormSelect } from "v2/react/shared/forms/FormSelect"
import { Footer } from "v2/react/shared/layout/Sidebar/Footer"
import type { Filters } from "v2/react/shared/tables/TableUtilities/FilterPositionReport/Panel"
import { UnappliedChangesModal } from "v2/react/shared/tables/TableUtilities/UnappliedChangesModal"
import { maybeGetIDFromUniqueKey } from "v2/react/utils/uniqueKey"

interface Props {
  collections: Collection[]
  filters: Filters
  initialFilters: Filters
  handleFilterChange: (e: React.ChangeEvent<HTMLSelectElement>) => void
  onCancel: () => void
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void
}

const PanelContent = ({
  collections,
  filters,
  initialFilters,
  handleFilterChange,
  onCancel,
  onSubmit,
}: Props) => {
  const { t } = useTranslation()
  const [formKey, setFormKey] = useState(0)

  const showFooter = !isEqual(omitBy(filters, isEmpty), omitBy(initialFilters, isEmpty))
  UnappliedChangesModal.useSetUnappliedChanges(showFooter)

  const handleCancel = () => {
    setFormKey((prev) => prev + 1)
    onCancel?.()
  }

  return (
    <form key={formKey} onSubmit={onSubmit} className={cn({ "h-full !pb-10": showFooter })}>
      <div className="h-full overflow-y-scroll p-3">
        {collections.map((collection) => {
          let fieldKey = collection.name
          if (fieldKey === "position_importance") {
            fieldKey = "importance"
          }

          return (
            <FormSelect
              key={fieldKey}
              id={fieldKey}
              name={`reports_filter[${fieldKey}]`}
              label={collection.label}
              options={getSelectOptions(collection, t)}
              selected={initialFilters[fieldKey]?.trim()}
              onSelect={handleFilterChange}
            />
          )
        })}
      </div>
      <Footer show={showFooter} onCancel={handleCancel} />
    </form>
  )
}

const getSelectOptions = (collection: Collection, t: TFunction) => {
  const placeholderOption = {
    id: "",
    label: getPlaceholderLabel(collection),
  }
  const noneOption = {
    id: "none",
    label: t("v2.defaults.none"),
  }
  const options = collection.options?.nodes?.map((node) => ({
    id: maybeGetIDFromUniqueKey(node.id as string) || "",
    label: node.label,
  }))

  return [placeholderOption, ...options, noneOption]
}

export { PanelContent }
