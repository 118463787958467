import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { useTranslation } from "react-i18next"

import { Person, Position, ProfilePanelPermissions } from "types/graphql"
import { ProfilePanelMode } from "types/graphql.enums"
import { UrlHelper } from "v2/react/utils/urls"

import { OpenLinkInNewTab } from "./Icons/OpenLinkInNewTab"
import { PanelToggle } from "./PanelToggle"
import { IS_V2 } from "./utils/constants"

interface ProfilePanelHeaderProps {
  abilities?: ProfilePanelPermissions
  handleOpenSettings: () => void
  handleV2Close: () => void
  isOfficialChart: boolean
  panelMode?: ProfilePanelMode
  person?: Person | null
  position?: Position | null
}

const ProfilePanelHeader: React.FC<ProfilePanelHeaderProps> = ({
  abilities,
  handleOpenSettings,
  handleV2Close,
  isOfficialChart,
  panelMode,
  person,
  position,
}) => {
  const { t } = useTranslation()

  const conditionsForLoading = (!person && !position) || !abilities || !panelMode
  if (conditionsForLoading) return null

  return (
    <div className="react-profile-panel__actions">
      <div />
      <PanelToggle panelMode={panelMode} />
      <div className="react-profile-panel__actions-icons">
        <div className="tooltip tooltip-right" data-testid="settings-icon-button">
          <FontAwesomeIcon
            className="cursor-pointer"
            icon={["far", "cog"]}
            style={{ width: "20px", height: "20px" }}
            onClick={handleOpenSettings}
          />
          <span className="tooltip-content tooltip-content--sm">
            {t("v2.profile_panel.configure_panel")}
          </span>
        </div>
        {panelMode === ProfilePanelMode.Person && (
          <div className="tooltip tooltip-right">
            <OpenLinkInNewTab url={UrlHelper.personProfilePath(person?.id || "")} />
            <span className="tooltip-content tooltip-content--sm">
              {t("v2.profile_panel.view_profile")}
            </span>
          </div>
        )}
        {panelMode === ProfilePanelMode.Position &&
          abilities?.canShowPositionPage &&
          isOfficialChart && (
            <div className="tooltip tooltip-right">
              <OpenLinkInNewTab url={UrlHelper.positionShowPath(position?.id || "")} />
              <span className="tooltip-content tooltip-content--sm">
                {t("v2.profile_panel.view_position")}
              </span>
            </div>
          )}
        <FontAwesomeIcon
          className="cursor-pointer"
          icon={["far", "times"]}
          size="2x"
          data-action={IS_V2 ? undefined : "hide-panel"}
          onClick={IS_V2 ? handleV2Close : undefined}
        />
      </div>
    </div>
  )
}

export { ProfilePanelHeader }
