/* eslint-disable no-nested-ternary */
import React from "react"

import { HeadcountPlan } from "types/graphql.d"
import { DisplayValueCell } from "v2/react/components/headcountPlanning/TableDatasheet/DisplayValueCell"
import { StrikethroughCell } from "v2/react/components/headcountPlanning/TableDatasheet/StrikethroughCell"
import { Column, FieldType } from "v2/react/components/headcountPlanning/TableDatasheet/types"

import { DatePickerCell } from "./DatePickerCell"
import { DropdownCell } from "./DropdownCell"
import { EndDateCell } from "./EndDateCell"
import { ForcedAutocompleteCell } from "./ForcedAutocompleteCell"
import { JustificationCell } from "./JustificationCell"
import { LocationCell } from "./LocationCell"
import { NonEditableCell } from "./NonEditableCell"
import { PositionMenuCell } from "./PositionMenuCell"
import { ReportsToCell } from "./ReportsToCell"
import { StandardEditableCell } from "./StandardEditableCell"
import { StatusCell } from "./StatusCell"
import { SubmittedByCell } from "./SubmittedByCell"
import { SuggestedAutocompleteCell } from "./SuggestedAutocompleteCell"
import { TypeCell } from "./TypeCell"
import { HeadcountPlanDatasheetRow } from "./types"

type Props = {
  forEntireOrg: boolean
  row: HeadcountPlanDatasheetRow
  column: Column<HeadcountPlanDatasheetRow>
  headcountPlanId: string
  headcountPlan: HeadcountPlan | undefined
  participantId?: string
  participantPositionId?: string
  readOnly: boolean
}
export function HeadcountPlanDatasheetCell({
  forEntireOrg,
  row,
  column,
  headcountPlanId,
  headcountPlan,
  participantId,
  participantPositionId,
  readOnly,
}: Props) {
  if (column.id === "status") {
    return <StatusCell rowId={row.id} status={row.status} />
  }
  if (column.id === "type") {
    return (
      <TypeCell
        endDate={row.payload.end_date}
        rowId={row.id}
        type={row.type}
        excluded={row.excluded}
      />
    )
  }
  if (column.id === "options") {
    return (
      <PositionMenuCell
        headcountPlanId={headcountPlanId}
        headcountPlanStartDate={headcountPlan?.startDate}
        participantId={participantId}
        participantPositionId={participantPositionId}
        row={row}
      />
    )
  }
  if (column.id === "position_id") {
    return (
      <DisplayValueCell
        id={`${column.id}-${row.id}`}
        className="non-editable-cell h-full flex-col justify-center flex"
      >
        {row.systemId}
      </DisplayValueCell>
    )
  }
  if (column.id === "end_date") {
    return (
      <EndDateCell
        row={row}
        headcountPlanId={headcountPlanId}
        participantId={participantId}
        readOnly={readOnly}
      />
    )
  }
  if (column.id === "location") {
    return (
      <LocationCell
        row={row}
        headcountPlanId={headcountPlanId}
        participantId={participantId}
        readOnly={readOnly}
      />
    )
  }
  if (column.id === "reports_to") {
    return (
      <ReportsToCell
        forEntireOrg={forEntireOrg}
        row={row}
        headcountPlanId={headcountPlanId}
        participantId={participantId}
        readOnly={readOnly}
      />
    )
  }
  if (column.id === "justification") {
    return (
      <JustificationCell
        row={row}
        headcountPlanId={headcountPlanId}
        participantId={participantId}
        readOnly={readOnly}
      />
    )
  }
  if (column.id === "submittedBy") {
    return <SubmittedByCell rowId={row.id} content={row.submittedBy} excluded={row.excluded} />
  }
  if (column.fieldType === FieldType.ForcedAutocomplete) {
    return (
      <ForcedAutocompleteCell
        row={row}
        column={column}
        headcountPlanId={headcountPlanId}
        participantId={participantId}
        readOnly={readOnly}
      />
    )
  }
  if (column.fieldType === FieldType.SuggestedAutocomplete) {
    return (
      <SuggestedAutocompleteCell
        row={row}
        column={column}
        headcountPlanId={headcountPlanId}
        participantId={participantId}
        readOnly={readOnly}
      />
    )
  }

  if (column.fieldType === FieldType.Standard) {
    return (
      <StandardEditableCell
        row={row}
        column={column}
        headcountPlanId={headcountPlanId}
        participantId={participantId}
        readOnly={readOnly}
        editable={column.editableFn?.(row)}
      />
    )
  }
  if (column.fieldType === FieldType.SelectDropdown) {
    return (
      <DropdownCell
        row={row}
        column={column}
        headcountPlanId={headcountPlanId}
        participantId={participantId}
        readOnly={readOnly}
      />
    )
  }
  if (column.fieldType === FieldType.DatePicker) {
    return (
      <DatePickerCell
        row={row}
        column={column}
        headcountPlanId={headcountPlanId}
        participantId={participantId}
        readOnly={readOnly}
        editable={column.editableFn?.(row)}
      />
    )
  }
  if (column.fieldType === FieldType.NonEditable) {
    return (
      <NonEditableCell
        row={row}
        column={column}
        readOnly={readOnly}
        currentValueOverride={
          column.id === "filled"
            ? `position_${row.filled ? "filled" : "open"}`.t("headcount_plan")
            : undefined
        }
      />
    )
  }

  const content = row.positionAttributesWithEdits[column.id] ?? ""

  if (row.excluded) {
    return <StrikethroughCell value={content} />
  }

  return <DisplayValueCell id={`${column.id}-${row.id}`}>{content}</DisplayValueCell>
}
