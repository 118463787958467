import dayjs from "dayjs"
import React from "react"

import { PosigenFormShape } from "v2/react/components/jobRequisitions/RequisitionForm/types/PosigenFormShape"
import {
  basePayLabel,
  compensationLabelPrefix,
} from "v2/react/shared/jsonForms/JsonFormControls/utils/compensation"

import { ListItem } from "./ListItem"

interface Props {
  data: PosigenFormShape
}

function PosigenModal({ data }: Props) {
  return (
    <div className="module-card">
      <div className="module-card__header">{"position_info".t("job_requisition")}</div>
      <div className="module-card__body">
        {ListItem("field_title".t("org_chart"), data.position.jobTitle?.name)}
        {ListItem("field_reports_to".t("org_chart"), data.position.reportsTo?.name)}
        {ListItem("field_department".t("org_chart"), data.position.department?.name)}
        {ListItem("field_location".t("org_chart"), data.position.location?.name)}
        {ListItem("field_employee_type".t("org_chart"), data.position.employeeType?.name)}
        {ListItem("field_flsa_classification".t("org_chart"), data.position.flsa?.name)}
        {ListItem(
          `${compensationLabelPrefix(data)} ${"field_base_pay".t("org_chart")}`,
          basePayLabel(
            data.position.basePay?.payType?.id,
            data.position.basePay?.amount,
            data.position.basePay?.hoursPerWeek,
          ),
        )}
        {ListItem(
          "field_projected_hire_date".t("org_chart"),
          data.position.projectedHireDate
            ? dayjs(data.position.projectedHireDate).format("MM/DD/YYYY")
            : "",
        )}
      </div>
    </div>
  )
}

export { PosigenModal }
