import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import dayjs from "dayjs"
import { TFunction } from "i18next"
import React, { useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"

import {
  Person,
  Position,
  ProfilePanelPermissions,
  QueryPositionArgs,
  UpdateProfilePanelModeSettingInput,
} from "types/graphql"
import { ProfilePanelMode } from "types/graphql.enums"
import { ArrowNavigator } from "v2/react/components/orgChart/ProfilePanel/ArrowNavigator"
import { PositionActions } from "v2/react/components/orgChart/ProfilePanel/PositionHeader/PositionActions"
import { StickyHeader } from "v2/react/components/orgChart/ProfilePanel/StickyHeader"
import { pjaxModalFor } from "v2/react/utils/pjax"
import { entityFromUniqueKey } from "v2/react/utils/uniqueKey"
import { UrlHelper } from "v2/react/utils/urls"
import { useUpdateProfilePlanModeSettingMutation } from "v2/redux/GraphqlApi/PeopleApi"
import {
  closeProfilePlanSidebar,
  setPersonId,
  setPositionId,
  setProfilePanelMode,
} from "v2/redux/slices/ProfilePanelSlice"
import { useAppDispatch, useAppSelector } from "v2/redux/store"

import { IS_V2 } from "./utils/constants"
import { constructSiblingsNavigationList } from "./utils/constructSiblingsNavigationList"

interface PositionHeaderProps {
  abilities: ProfilePanelPermissions
  pjaxReloadContainer?: string
  pjaxReloadUrl?: string
  positionQuery: (params: QueryPositionArgs) => void
}

function PositionHeader({
  abilities,
  positionQuery,
  pjaxReloadContainer,
  pjaxReloadUrl,
}: PositionHeaderProps) {
  const person = useAppSelector((state) => state.profilePanel.person)
  const personId = useAppSelector((state) => state.profilePanel.personId)
  const position = useAppSelector((state) => state.profilePanel.position)
  const positionId = useAppSelector((state) => state.profilePanel.positionId)
  const displayMode = useAppSelector((state) => state.visualization.displayMode)
  const containerKey = useAppSelector((state) => state.container.containerKey)
  const [updateMode] = useUpdateProfilePlanModeSettingMutation()

  const dispatch = useAppDispatch()
  const panel = document.getElementById("side-panel__profile") as HTMLDivElement
  const panelRef = useRef<HTMLDivElement>(panel)
  const headerRef = useRef<HTMLDivElement>(null)
  const { t } = useTranslation()

  const containerType = containerKey && entityFromUniqueKey(containerKey)
  const isListView = containerType === "list"
  const canReadRestrictedPositionFields = abilities.canReadRestrictedPositionFields

  useEffect(() => {
    if (!position || position.id !== positionId || (personId && !person)) return
    if (!IS_V2) {
      if (person) {
        window.App.OrgChart.switchPeople(Number(positionId), Number(personId))
      }

      window.App.OrgChart.focus(Number(positionId))
    }
  }, [person, position, personId, positionId, dispatch])

  const handlePositionNavigation = (item: Position) => {
    if (!position?.id) return
    if (position.id !== item.id) dispatch(setPositionId(item.id))
    // when navigating through positions, determine if a person should be stored as well
    // this will make sure that the person panel mode is disabled for unfilled positions
    if (item.people?.length) dispatch(setPersonId(item.people[0].id))
    if (!item.people?.length) dispatch(setPersonId(null))
  }

  if (!position) return null

  const selectPerson = (person: Person) => {
    dispatch(setProfilePanelMode(ProfilePanelMode.Person))
    dispatch(setPersonId(person.id))
    const input: UpdateProfilePanelModeSettingInput = {
      profilePanelMode: ProfilePanelMode.Person,
    }
    updateMode(input)
  }

  const isTopNode = !IS_V2
    ? window.App.OrgChart.getNodeByPosition(Number(position.id))?.attributes.depth === 0
    : false

  const peopleButtons = () =>
    (position?.people || []).map((person: Person) => (
      <button
        type="button"
        key={person.id}
        className="badge--link hover"
        onClick={() => selectPerson(person)}
      >
        {person.avatarThumbUrl && (
          <img
            src={person.avatarThumbUrl}
            alt={t("v2.profile_panel.profile_image")}
            className="h-4 w-4 rounded-full"
          />
        )}
        {person.name}
        <FontAwesomeIcon icon={["far", "chevron-right"]} className="text-primary-50" />
      </button>
    ))

  const positionList = !IS_V2 ? constructPositionNavigationList(position) : []

  const handleClose = () => {
    if (IS_V2) {
      dispatch(closeProfilePlanSidebar())
    }
  }

  const handleEditPosition = () => {
    if (!position.id) return
    pjaxModalFor(UrlHelper.editPositionPath(position.id.toString()))
  }

  return (
    <>
      <StickyHeader containerRef={panelRef} elementRef={headerRef}>
        <div className="items-center justify-between flex">
          <h1 className="title m-0 text-xl font-bold">
            {position.title || t("v2.profile_panel.untitled_position")}
          </h1>
          <FontAwesomeIcon
            className="cursor-pointer"
            icon={["far", "times"]}
            size="2x"
            data-action={IS_V2 ? undefined : "hide-panel"}
            onClick={IS_V2 ? handleClose : undefined}
          />
        </div>
      </StickyHeader>

      <div className="react-profile-panel__position-profile" ref={headerRef}>
        <div className="w-full flex">
          <div className="w-full items-center gap-2 pr-2 flex">
            <h1 className="title m-0 text-xl font-bold">
              {position.title || t("v2.profile_panel.untitled_position")}
            </h1>
            {!isListView && (
              <PositionActions
                canDelete={abilities.canEditPosition}
                canEdit={abilities.canEditPosition}
                canViewHistory={canReadRestrictedPositionFields}
                canViewSystemIdentifier={canReadRestrictedPositionFields}
                containerKey={containerKey}
                hasChildren={!!position.childrenCount}
                isHeadcountPlanner={position.isHeadcountPlanner || false}
                isV2={IS_V2}
                pjaxReloadContainer={pjaxReloadContainer}
                pjaxReloadUrl={pjaxReloadUrl}
                positionId={position.id}
                positionQuery={positionQuery}
                systemIdentifier={position.systemIdentifier}
              />
            )}
          </div>
          {!IS_V2 && (
            <ArrowNavigator
              items={positionList}
              disableButtons={
                position.siblings?.length === 1 ||
                !["three_level", "tree"].includes(displayMode) ||
                isTopNode
              }
              currentItemIndex={positionList.findIndex((item) => item.id === position.id)}
              selectItem={handlePositionNavigation}
              tooltipLookup={(item: Position) =>
                item.title || t("v2.profile_panel.untitled_position")
              }
            />
          )}
        </div>
        <p className="mb-4">
          {t("v2.profile_panel.reporting_to")} {determineReportsTo(position, t)}
        </p>
        <div className="flex-wrap items-center gap-2 flex">
          <span className="w-fit">{t("v2.profile_panel.filled_by")}</span>
          {peopleButtons()}
          {!position.people?.length && (
            <div className="badge--link hover">
              <span className="text-neutral-80">{determineOpenSince(position, t)}</span>
              {abilities.canEditPosition && (
                <>
                  <button className="link-text" type="button" onClick={handleEditPosition}>
                    {t("v2.profile_panel.fill_position")}
                  </button>
                  <FontAwesomeIcon icon={["far", "chevron-right"]} className="text-primary-50" />
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export { PositionHeader }

export const determineReportsTo = (position: Position, t: TFunction) => {
  if (position.reportsToName) {
    const nameIsNotTitle = position.reportsToName !== position.reportsToTitle
    if (nameIsNotTitle)
      return `${position.reportsToName}, ${
        position.reportsToTitle || t("v2.profile_panel.untitled_position")
      }`
  }
  if (position.reportsToTitle) {
    return position.reportsToTitle
  }
  return position.reportsToSystemIdentifier
}

const determineOpenSince = (position: Position, t: TFunction) => {
  const dateDifference = dayjs(Date.now()).diff(position.openSince, "days")

  if (dateDifference < 1) {
    return t("v2.profile_panel.open_for_less_than_day")
  }
  return t("v2.profile_panel.open_for_x_days", { count: dateDifference })
}

/*
 * This maps over all sibling positions and returns a flattened array of
 * sibling positions. Because the array is given to us seemingly in order
 * of the 'id' attribute, it doesn't accurately reflect which position would
 * be the next position. To determine this, we need to get the 'x' attribute
 * from each node and order them smallest to largest.
 */
const constructPositionNavigationList = (position: Position) => {
  const siblings = position.siblings || []
  const withPositionId = (node: Position) => node.id

  return constructSiblingsNavigationList<Position>(position, siblings, withPositionId)
}
