import React from "react"

import { CompareValues } from "v2/react/components/headcountPlanning/TableDatasheet/CompareValues"
import { DropdownCell as DropdownCellPrimitive } from "v2/react/components/headcountPlanning/TableDatasheet/DropdownCell"
import { StrikethroughCell } from "v2/react/components/headcountPlanning/TableDatasheet/StrikethroughCell"
import { Column } from "v2/react/components/headcountPlanning/TableDatasheet/types"

import { useSaveCell } from "../hooks/useSaveCell"
import { HeadcountPlanDatasheetRow } from "./types"

type Props = {
  row: HeadcountPlanDatasheetRow
  column: Column<HeadcountPlanDatasheetRow>
  headcountPlanId: string
  participantId?: string
  readOnly: boolean
}
export function DropdownCell({ row, column, participantId, headcountPlanId, readOnly }: Props) {
  const saveFn = useSaveCell(headcountPlanId, column.id, row, participantId)

  if (!column.options) {
    return null
  }

  const currentValue = row.positionAttributesWithEdits[column.id] ?? ""
  const currentLabel = column.options.find((option) => option.id === currentValue)?.label ?? ""
  const compareValue =
    row.type === "modified" && column.id in row.payload ? row.positionAttributes[column.id] : null
  const compareLabel = column.options.find((option) => option.id === compareValue)?.label ?? ""

  if (row.excluded) {
    return <StrikethroughCell value={currentLabel} />
  }

  if (readOnly) {
    return (
      <CompareValues compareValue={compareLabel} className="non-editable-cell">
        {currentLabel}
      </CompareValues>
    )
  }

  return (
    <DropdownCellPrimitive
      rowId={row.id}
      columnId={column.id}
      includeBlankOption={["employee_type", "flsa_classification"].includes(column.id)}
      options={column.options}
      saveFn={saveFn}
      currentValue={currentValue}
      compareValue={compareValue}
    />
  )
}
