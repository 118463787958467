import { ControlProps } from "@jsonforms/core"
import React, { ChangeEvent, FC } from "react"

import { DateInput } from "v2/react/shared/forms/DateInput"

import { useValidation } from "./hooks/useValidation"

const JsonDateInput: FC<ControlProps> = ({
  config,
  data,
  id,
  handleChange,
  label,
  path,
  schema,
}: ControlProps) => {
  const { showError, errorMessage } = useValidation({
    data,
    path,
    schema,
    submitting: config.submitting,
  })

  const handleChangeEvent = (event: ChangeEvent<HTMLInputElement>) =>
    handleChange(path, event.target.value)

  return (
    <DateInput
      defaultValue={data}
      emptyDefaultValue
      errors={showError ? errorMessage : ""}
      label={label}
      name={id}
      onChange={handleChangeEvent}
    />
  )
}

export { JsonDateInput }
